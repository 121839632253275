<template>
    <v-container fluid style="max-width:1200px;">
        <v-card elevation="0">
            <v-card-title>
                <v-btn @click="refreshBookings(true)" rounded color="primary" class="mx-2">
                    <v-icon class="mr-2">mdi-refresh</v-icon>
                    Refresh
                </v-btn>
                <v-spacer />
                <!-- <v-btn @click="openInvoice()" rounded color="primary" class="mx-2">
                    Create Invoice
                </v-btn> -->
            </v-card-title>
            <v-card-text>
                <InvoiceTable ref="general_payments" @openEditInvoice="openEditInvoice" :itinerary="itinerary"
                    :net="net" :gross="gross" />
            </v-card-text>
        </v-card>
        <CreateOrUpdateInvoiceDialog :show_invoice_dialog="show_invoice_dialog" :edit_invoice="edit_invoice"
            :invoice="invoice" @saveInvoice="saveInvoice" @editInvoice="editInvoice" @closeInvoice="closeInvoice" />
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import InvoiceTable from './components/InvoiceTable.vue'
import CreateOrUpdateInvoiceDialog from './dialogs/CreateOrUpdateInvoiceDialog.vue'
import html2pdf from 'html2pdf.js'
export default {
    components: {
        InvoiceTable, CreateOrUpdateInvoiceDialog,
    },
    props: [
        'itinerary', 'net', 'gross'
    ],
    data() {
        return {
            tab: null,
            show_invoice_dialog: false,
            invoice: {
                id: null,
                itinerary_id: null,
                created_by: null,
                payment_name: null,
                payment_type: null,
                amount_due: 0,
                service_charge: 0,
                due_date: null,
                method: null,
                invoice_number: null,
                bill_to: null,
                billing_address: null,
                notes: null,
                payer: null,
                amount_paid: null,
                date_paid: null,
                payment_note: null,
            },
            edit_invoice: false,
        }
    },
    async mounted() {
        this.invoice.itinerary_id = this.itinerary.id
        this.invoice.created_by = this.get_user.id
        this.getInvoices()
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_user: 'auth/get_user',
        }),
    },
    methods: {
        async refreshBookings(message = false) {
            this.$emit('refreshBookings')
            this.getInvoices()
            if (message) {
                this.$toast.info('Refreshing...')
            }
        },
        async getInvoices() {
            this.$store.dispatch('itinerary/fetch_invoices', { itinerary_id: this.itinerary.id })
        },
        generatePDF() {
            html2pdf(document.getElementById('payment-table'), {
                margin: 1,
                filename: this.$date(new Date()).format('YYYY-MM-DD') + '-invoices.pdf',
                image: { type: 'jpeg', quality: 1.0 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
            });
        },
        async openInvoice() {
            this.show_invoice_dialog = true
        },
        openEditInvoice(item) {
            this.edit_invoice = true
            this.show_invoice_dialog = true
            this.invoice.id = item.id
            this.invoice.itinerary_id = item.itinerary_id
            this.invoice.created_by = item.created_by
            this.invoice.payment_name = item.payment_name
            this.invoice.payment_type = item.payment_type
            this.invoice.amount_due = item.amount_due
            this.invoice.due_date = item.due_date
            this.invoice.method = item.method
            this.invoice.invoice_number = item.invoice_number
            this.invoice.bill_to = item.bill_to
            this.invoice.billing_address = item.billing_address
            this.invoice.notes = item.notes
            this.invoice.payer = item.payer
            this.invoice.amount_paid = item.amount_paid
            this.invoice.date_paid = item.date_paid
            this.invoice.payment_note = item.payment_note
            this.invoice.service_charge = item.service_charge
        },
        async saveInvoice() {
            await this.$axios.post('/itinerary/payments/create_invoice', this.invoice)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeInvoice()
                        this.$store.commit('itinerary/set_invoices', data.data)
                        this.$store.commit('itinerary/set_total_due', data.total_due)
                        this.$store.commit('itinerary/set_total_paid', data.total_paid)
                        this.refreshBookings()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        async editInvoice() {
            await this.$axios.patch('/itinerary/payments/edit_invoice', this.invoice)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeInvoice()
                        this.$store.commit('itinerary/set_invoices', data.data)
                        this.$store.commit('itinerary/set_total_due', data.total_due)
                        this.$store.commit('itinerary/set_total_paid', data.total_paid)
                        this.refreshBookings()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        closeInvoice() {
            this.show_invoice_dialog = false
            this.edit_invoice = false
            this.invoice.payment_name = null
            this.invoice.payment_type = null
            this.invoice.amount_due = null
            this.invoice.due_date = null
            this.invoice.method = null
            this.invoice.invoice_number = null
            this.invoice.bill_to = null
            this.invoice.billing_address = null
            this.invoice.notes = null
            this.invoice.payer = null
            this.invoice.amount_paid = null
            this.invoice.date_paid = null
            this.invoice.payment_note = null
        },
    },
    watch: {
    }
}
</script>

<style scoped>
</style>