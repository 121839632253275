<template>
    <v-dialog :value="create_payment_dialog" persistent max-width="500">
        <v-card>
            <v-card-title class="d-flex">
                Create Payment
                <v-spacer />
                <v-btn @click="close()" variant="text" icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-row class="justify-center">
                    <v-col cols="6">
                        <v-text-field v-model="new_payment.name" label="Payment Name" />
                    </v-col>
                    <v-col cols="6">
                        <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="5" :nudge-bottom="61"
                            transition="scale-transition" min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="new_payment.paid_date" label="Paid Date" readonly v-bind="attrs"
                                    v-on="on" />
                            </template>
                            <v-date-picker v-model="new_payment.paid_date" @input="menu = false;" />
                        </v-menu>
                    </v-col>
                    <v-col cols="6">
                        <v-select v-model="new_payment.payment_type_id" :items="payment_types" item-text="name"
                            item-value="id" label="Payment Type *" />
                    </v-col>
                    <v-col cols="6">
                        <v-select v-model="new_payment.currency_id" :items="get_currencies" item-text="code"
                            item-value="id" label="Currency" />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="new_payment.amount" @change="updateTotal" type="number" min="0"
                            label="Payment Amount *" />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="new_payment.surcharge" @change="updateTotal" type="number" min="0"
                            label="Surcharge" />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="new_payment.tip_amount" @change="updateTotal" type="number" min="0"
                            label="Tip" />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="new_payment.total_amount" type="number" disabled label="Total Amount" />
                    </v-col>
                    <v-col cols="6">
                        <v-select v-model="new_payment.method"
                            :items="['Bank transfer', 'Credit card', 'Cash', 'Cheque', 'Voucher', 'Stripe', 'Sagepay', 'Authipay', 'Other']"
                            label="Payment Method *" />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="new_payment.vendor_tx_code" label="Transaction Ref" />
                    </v-col>
                    <v-col cols="12">
                        <v-textarea v-model="new_payment.notes" label="Notes" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn @click="close()" rounded text class="px-5">
                    Cancel
                </v-btn>
                <v-btn @click="createPayment()" rounded color="primary" class="px-5">
                    Create
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: [
        'create_payment_dialog', 'itinerary', 'payment_types',
    ],
    data() {
        return {
            menu: false,
            new_payment: {
                company_id: this.itinerary.company_id,
                itinerary_id: this.itinerary.id,
                name: null,
                paid_date: null,
                paid_to_traveller_id: (this.itinerary.travellers && this.itinerary.travellers[0]) ? this.itinerary.travellers[0].id : 0,
                paid_by_traveller_id: null,
                paid_by_user_id: null,
                payment_type_id: 1,
                currency_id: this.itinerary.payment_currency_id,
                amount: 0,
                surcharge: 0,
                tip_amount: 0,
                total_amount: 0,
                vendor_tx_code: null,
                method: null,
                notes: null,
            },
        }
    },
    computed: {
        ...mapGetters({
            get_user: 'auth/get_user',
            get_currencies: 'itineraryV2/get_currencies',
        }),
    },
    async mounted() {
    },
    methods: {
        close() {
            this.$emit('close')
        },
        createPayment() {
            this.new_payment.paid_by_user_id = this.get_user.id
            this.$emit('createPayment', this.new_payment)
        },
        updateTotal() {
            this.new_payment.total_amount = parseFloat(this.new_payment.amount) + parseFloat(this.new_payment.surcharge) + parseFloat(this.new_payment.tip_amount)
        },
    },
}
</script>