<template>
    <div>
        <div class="header" v-if="!shareable_link">
            <div class="header-title">
                <h3>
                    {{ itinerary ? itinerary.itinerary_name : null }}
                </h3>
            </div>
            <div>
                <v-tabs dark background-color="#588BAD" v-model="tab" fixed-tabs>
                    <v-tab href="#tab-1">Setup</v-tab>
                    <v-tab href="#tab-2" :disabled="loading_bookings">Documents</v-tab>
                    <v-tab href="#tab-3" :disabled="loading_bookings">History</v-tab>
                    <v-tab href="#tab-4" :disabled="loading_bookings">Tasks</v-tab>
                    <v-tab href="#tab-5" :disabled="loading_bookings">Build</v-tab>
                    <v-tab href="#tab-6" :disabled="loading_bookings">
                        <v-icon v-if="itinerary && itinerary.gross_locked" color="white" class="mr-2">
                            mdi-lock-check
                        </v-icon>
                        Pricing
                    </v-tab>
                    <v-tab href="#tab-7" :disabled="loading_bookings">Bookings</v-tab>
                    <v-tab href="#tab-8" :disabled="loading_bookings">Payments</v-tab>
                    <v-tab href="#tab-9" :disabled="loading_bookings">Preview</v-tab>
                </v-tabs>
            </div>
        </div>
        <div>
            <v-tabs-items v-model="tab">
                <v-tab-item value="tab-1">
                    <v-card flat>
                        <SetupComponent :loading_itinerary="loading_bookings" :itinerary="itinerary"
                            @refreshItinerary="getItinerary" @refreshBookings="getBookingsWithRates" />
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
            <v-tabs-items v-model="tab">
                <v-tab-item value="tab-2">
                    <v-card flat>
                        <DocumentComponent :itinerary="itinerary" @refreshItinerary="getItinerary" />
                    </v-card>
                </v-tab-item>
                <v-tab-item value="tab-3">
                    <v-card flat>
                        <HistoryComponent :itinerary="itinerary" @refreshItinerary="getItinerary" />
                    </v-card>
                </v-tab-item>
                <v-tab-item value="tab-4">
                    <v-card flat>
                        <TaskComponent :itinerary="itinerary" @refreshItinerary="getItinerary" />
                    </v-card>
                </v-tab-item>
                <v-tab-item value="tab-5">
                    <v-card flat>
                        <BuildComponent :itinerary="itinerary" :loading_bookings="loading_bookings" :days="days"
                            @refreshItinerary="getItinerary" @refreshBookings="getBookingsWithRates"
                            @onDragEnd="onDragEnd" ref="buildComponent" />
                    </v-card>
                </v-tab-item>
                <v-tab-item value="tab-6">
                    <v-card flat>
                        <PricingComponent :itinerary="itinerary" :loading_bookings="loading_bookings"
                            :bookings="bookings_with_rates" :net="net" :gross="gross" :exchange_rates="exchange_rates"
                            :gross_without_orr="gross_without_orr" :saving_price="saving_price"
                            @refreshItinerary="getItinerary" @refreshBookings="getBookingsWithRates"
                            @savingPrice="savingPrice" />
                    </v-card>
                </v-tab-item>
                <v-tab-item value="tab-7">
                    <v-card flat>
                        <BookingComponent :itinerary="itinerary" :loading_bookings="loading_bookings"
                            :bookings="bookings_with_rates" @refreshItinerary="getItinerary"
                            @refreshBookings="getBookingsWithRates" />
                    </v-card>
                </v-tab-item>
                <v-tab-item value="tab-8">
                    <v-card flat>
                        <PaymentComponent :itinerary="itinerary" :loading_bookings="loading_bookings" :net="net"
                            :gross="gross" :exchange_rates="exchange_rates" @refreshItinerary="getItinerary"
                            @refreshBookings="getBookingsWithRates" />
                    </v-card>
                </v-tab-item>
                <v-tab-item value="tab-9">
                    <v-card flat>
                        <PreviewComponent :itinerary="itinerary" :loading_bookings="loading_bookings" :days="days"
                            :bookings="bookings_with_rates" :gross="gross" :shareable_link="shareable_link"
                            @refreshItinerary="getItinerary" @refreshBookings="getBookingsWithRates"
                            ref="previewComponent" />
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SetupComponent from './setup/SetupComponent.vue'
import DocumentComponent from './documents/DocumentComponent.vue'
import HistoryComponent from './history/HistoryComponent.vue'
import TaskComponent from './tasks/TaskComponent.vue'
import BuildComponent from './builds/BuildComponent.vue'
import PricingComponent from './pricing/PricingComponent.vue'
import BookingComponent from './booking/BookingComponent.vue'
import PaymentComponent from './payments/PaymentComponent.vue'
import PreviewComponent from './preview/PreviewComponent.vue'

export default {
    name: 'ItineraryComponent',
    components: {
        SetupComponent,
        DocumentComponent,
        HistoryComponent,
        TaskComponent,
        BuildComponent,
        PricingComponent,
        BookingComponent,
        PaymentComponent,
        PreviewComponent,
    },
    data: () => ({
        shareable_link: false,
        loading_itinerary: true,
        loading_bookings: true,
        itinerary_id: null,
        itinerary: null,
        days: [],
        bookings_with_rates: [],
        net: 0,
        gross: 0,
        gross_without_orr: 0,
        menu: false,
        tab: null,
        dragged: false,
        saving_price: false,
        exchange_rates: [],
    }),
    computed: {
        ...mapGetters({
        }),
    },
    async mounted() {
        this.itinerary_id = parseInt(this.$route.params.id)
        if (this.$route.name == 'Preview Itinerary') {
            this.shareable_link = true
            this.decodeBase62()
        }
        else {
            this.getItinerary()
        }
    },
    methods: {
        decodeBase62() {
            const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
            let result = 0

            for (let i = 0; i < this.$route.params.id.length; i++) {
                result = result * 62 + chars.indexOf(this.$route.params.id[i])
            }

            let mid = Math.ceil(result.toString().length / 2)
            this.itinerary_id = result.toString().substring(0, mid)

            this.tab = 'tab-9'
            this.getItinerary()
        },
        async getItinerary(refresh = false) {
            await this.$axios.get(`v2/itineraries/get_itinerary/${this.itinerary_id}`)
                .then(({ data }) => {
                    if (data.response) {
                        if (refresh == true) {
                            this.$toast.success('Refreshed itinerary successfully')
                        }
                        this.itinerary = data.data
                        this.loading_itinerary = false

                        this.exchange_rates['EUR'] = data.eur
                        this.exchange_rates['GBP'] = data.gbp
                        this.exchange_rates['ISK'] = data.isk
                        this.exchange_rates['USD'] = data.usd

                        document.title = 'PMT – ' + this.itinerary.itinerary_name + ' Itinerary'
                        this.getBookingsWithRates()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        async getBookingsWithRates(refresh = false) {
            await this.$axios.get(`v2/itineraries/get_bookings_with_rates/${this.itinerary_id}`)
                .then(({ data }) => {
                    if (data.response) {
                        if (refresh == true) {
                            this.$toast.success('Refreshed bookings successfully')
                        }
                        this.days = data.days
                        this.bookings_with_rates = data.bookings
                        this.loading_bookings = false

                        if (this.itinerary.gross_locked == 0) {
                            this.calcTotalNet()
                        }
                        else {
                            this.net = this.itinerary.net
                            this.gross = this.itinerary.gross
                            this.gross_without_orr = parseFloat(this.itinerary.gross) - parseFloat(this.itinerary.orr)
                        }

                        if (this.dragged == false) {
                            const accommodations = this.bookings_with_rates.filter(booking => booking.option.product.supplier.service_type_id === 1
                                && booking.option.product.supplier_product_type_id === 1
                                && this.$date(booking.check_out_date).diff(this.$date(booking.check_in_date), 'd') > 0).sort((a, b) => new Date(a.check_in_date) - new Date(b.check_in_date))
                            if (accommodations.length > 1) {
                                for (let i = 0; i < accommodations.length - 1; i++) {
                                    const current_booking = accommodations[i]
                                    const next_booking = accommodations[i + 1]

                                    if (!this.shareable_link && this.$date(current_booking.check_out_date).isBefore(this.$date(next_booking.check_in_date))) {
                                        this.$toast.error(`BUILD TAB ISSUE: No accommodation set on the night of ${this.$date(current_booking.check_out_date).format('MMM DD, YYYY')}!`, { timeout: 15000 })
                                    }
                                }
                            }

                            let bookings_without_rates = []
                            bookings_without_rates = this.bookings_with_rates.filter(booking => !booking.option.is_free && (booking.rate_day == null || booking.net == 0))
                            if (!this.shareable_link && bookings_without_rates.length > 0) {
                                this.$toast.error('PRICING TAB ISSUE: One or more bookings have no rates', { timeout: 15000 })
                            }
                        }

                        this.$nextTick(() => {
                            if (this.$refs.buildComponent) {
                                this.$refs.buildComponent.getDaysWithPreview()
                            }
                            if (this.$refs.previewComponent) {
                                this.$refs.previewComponent.getDaysWithPreview()
                            }
                        })

                        if (this.dragged) {
                            this.$nextTick(() => {
                                this.$toast.success('Reloaded build tab')
                                this.dragged = false
                            })
                        }
                        this.saving_price = false
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        onDragEnd() {
            this.dragged = true
        },
        calcTotalNet() {
            let total = 0
            this.bookings_with_rates.forEach(booking => {
                let days = (this.itinerary.count_transpo_end_date == 1 && booking.option.product.supplier.service_type_id == 3)
                    ? this.$date(booking.check_out_date).diff(this.$date(booking.check_in_date), 'd') + 1
                    : this.$date(booking.check_out_date).diff(this.$date(booking.check_in_date), 'd')

                let conversion = this.exchange_rates[booking.option.product.supplier.currency.code][this.itinerary.currency.code]
                total += (booking.net ?? 0) * (days > 0 ? days : 1) * booking.count * conversion
            })
            this.net = Math.abs(total)
            this.calcTotalGross()
        },
        calcTotalGross() {
            let total = 0
            this.bookings_with_rates.forEach(booking => {
                let days = (this.itinerary.count_transpo_end_date == 1 && booking.option.product.supplier.service_type_id == 3)
                    ? this.$date(booking.check_out_date).diff(this.$date(booking.check_in_date), 'd') + 1
                    : this.$date(booking.check_out_date).diff(this.$date(booking.check_in_date), 'd')

                let conversion = this.exchange_rates[booking.option.product.supplier.currency.code][this.itinerary.currency.code]
                total += (booking.gross ?? 0) * (days > 0 ? days : 1) * booking.count * conversion
            })
            this.gross = Math.abs(total) + parseFloat(this.itinerary.service_charge) + parseFloat(this.itinerary.orr)
            this.gross_without_orr = Math.abs(total) + parseFloat(this.itinerary.service_charge)
            if (!this.shareable_link) {
                this.updateItineraryNetAndGross()
            }
        },
        async updateItineraryNetAndGross() {
            let payload = {
                net: this.net,
                gross: this.gross,
            }
            await this.$axios.post(`v2/itineraries/update_itinerary/${this.itinerary.id}`, payload)
        },
        savingPrice(is_saving) {
            this.saving_price = is_saving
        },
    },
    watch: {
        tab() {
            window.scrollTo({
                top: 0,
                left: 0,
            })
        },
    },
}
</script>

<style scoped>
.header {
    position: sticky;
    z-index: 3;
    top: 64px;
}

.header .header-title {
    line-height: 50px;
    background-color: #79a2bd;
    box-shadow: 0 2px 4px 0px rgb(0 0 0 / 20%);
    position: relative;
    z-index: 1;
}

.header .header-title h3 {
    color: white;
    text-align: center;
}

:deep(button) {
    text-transform: unset;
}
</style>
