<template>
    <v-container fluid style="background-color: #F4F4F5 !important; height: 100%;" class="pa-0 ma-0">
        <v-app-bar app clipped-left dark color="#588BAD" elevation="0">
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <v-toolbar-title style="color:white;">
                PlanMyTour
            </v-toolbar-title>
            <v-spacer />
            <div>
                <label class="mr-10">
                    Hi, {{ get_user.name }}
                </label>
                <v-btn @click="show_pets = !show_pets" icon small>
                    <v-icon v-if="!show_pets" color="#5384A4">mdi-toggle-switch-off-outline</v-icon>
                    <v-icon v-else color="#5384A4">mdi-toggle-switch-outline</v-icon>
                </v-btn>
                <!-- <v-menu offset-y left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn text v-bind="attrs" v-on="on"
                            style="text-transform:initial;letter-spacing:initial;font-size:16px;">
                            <v-icon class="mr-3">mdi-bell</v-icon>
                            <b>Notifications</b>
                        </v-btn>
                    </template>
<v-list>
    <v-list-item>
        Test test test test test test test test test test test test test
    </v-list-item>
</v-list>
</v-menu> -->
            </div>
        </v-app-bar>
        <v-card elevation="12" width="256" style="border-radius:0px;">
            <v-navigation-drawer floating clipped app v-model="drawer" color="#447fa6" class="no-border shadow">
                <v-card-text style="color: white !important;">
                    <v-btn @click="openSelectCompanyDialog()" text block class="py-15"
                        style="text-transform:initial;letter-spacing:initial;">
                        <div v-if="get_selected_company.id" style="font-size: 16px; color: white;">
                            <v-img v-if="get_selected_company.company_logo" :src="get_selected_company.company_logo"
                                width="150" height="50" contain class="mb-3" />
                            <label style="font-size:14px;cursor:pointer;">Selected Company</label><br>
                            <b>{{ get_selected_company.company_name }}</b>
                        </div>
                        <div v-else style="font-size: 16px; color: white;">
                            <b>Please select a company</b>
                        </div>
                    </v-btn>
                </v-card-text>
                <v-divider />
                <v-list dense rounded v-if="get_selected_company.id">
                    <v-list-item @click="$router.push(item.url)" v-for="(item, itemindex) in navigation"
                        :key="itemindex" link>
                        <v-list-item-icon>
                            <v-icon :style="item.style">{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title :style="item.style">{{ item.label }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="$router.push({ name: 'Suppliers' })"
                        v-if="get_user.user_role_id == 1 || get_user.access_supplier == 1" link>
                        <v-list-item-icon>
                            <v-icon style="color:white;">mdi-account-box-multiple-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title style="color:white;">Suppliers</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-divider />
                <template v-slot:append>
                    <div>
                        <v-list v-if="get_user.user_role_id == 1" dense rounded>
                            <h4 style="color:white;">Agency Settings</h4>
                            <v-list-item v-for="(item, itemindex) in navigation2" :key="itemindex"
                                @click="$router.push(item.url)" link>
                                <v-list-item-icon>
                                    <v-icon :style="item.style">{{ item.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title :style="item.style">{{ item.label }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <v-list dense rounded>
                            <v-list-item @click="logout()" link>
                                <v-list-item-icon>
                                    <v-icon style="color:white;">mdi-logout</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title style="color:white;">Logout</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </div>
                </template>
            </v-navigation-drawer>
        </v-card>
        <main class="main">
            <slot />
        </main>
        <v-row justify="center">
            <v-dialog max-width="620" v-model="select_company_dialog" persistent>
                <v-card v-if="accesses">
                    <v-card-title>
                        Choose Company
                        <v-spacer></v-spacer>
                        <v-icon @click="closeSelectCompanyDialog()">mdi-close</v-icon>
                    </v-card-title>
                    <v-card-text>
                        <v-autocomplete v-model="company_selected"
                            :items="get_companies.filter(company => accesses.includes(company.id))" item-value="id"
                            item-text="company_name" label="Choose a company to work with today" outlined dense
                            return-object>
                        </v-autocomplete>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="selectCompany()" block dark color="#588BAD" rounded>
                            Select
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <div v-if="show_pets">
            <v-img :src="random_number_2 == 1
                ? 'https://storage.googleapis.com/goirishtours/chicken.gif' : random_number_2 == 2
                    ? 'https://storage.googleapis.com/goirishtours/chicken2.gif' : random_number_2 == 3
                        ? 'https://storage.googleapis.com/goirishtours/cat.gif' : random_number_2 == 4
                            ? 'https://storage.googleapis.com/goirishtours/bug.gif'
                            : 'https://storage.googleapis.com/goirishtours/dog.gif'" v-for="i in increment" :key="i"
                :width="random_number_2 == 3 || random_number_2 == 4 ? '40px' : '24px'"
                :height="random_number_2 == 3 || random_number_2 == 4 ? '40px' : '24px'"
                :class="random_number_2 < 3 ? 'chicken' : 'animal'" contain />
        </div>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    data: () => ({
        show_pets: false,
        increment: 1,
        random_number: 5000,
        random_number_2: 1,
        navigation: [
            {
                icon: 'mdi-home-outline',
                label: 'Home',
                style: 'color: white',
                url: {
                    name: 'Dashboard'
                }
            },
            {
                icon: 'mdi-clipboard-list-outline',
                label: 'Itineraries',
                style: 'color: white',
                url: {
                    name: 'Itineraries'
                }
            },
            {
                icon: 'mdi-book-clock',
                label: 'Bookings',
                style: 'color: white',
                url: {
                    name: 'Bookings'
                }
            },
            {
                icon: 'mdi-folder-multiple-outline',
                label: 'Resources',
                style: 'color: white',
                url: {
                    name: 'Resources'
                }
            },
            {
                icon: 'mdi-account-group-outline',
                label: 'Contacts',
                style: 'color: white',
                url: {
                    name: 'Contacts'
                }
            },
            {
                icon: 'mdi-domain',
                label: 'Agencies',
                style: 'color: white',
                url: {
                    name: 'Agencies'
                }
            },
            {
                icon: 'mdi-file-tree-outline',
                label: 'Tasks',
                style: 'color: white',
                url: {
                    name: 'Tasks'
                }
            },
        ],
        navigation2: [
            {
                icon: 'mdi-cog-outline',
                label: 'Companies',
                style: 'color: white',
                url: {
                    name: 'Companies'
                }
            },
            {
                icon: 'mdi-account-group-outline',
                label: 'Users',
                style: 'color: white',
                url: {
                    name: 'Users'
                }
            },
            {
                icon: 'mdi-calendar',
                label: 'Reminders',
                style: 'color: white',
                url: {
                    name: 'Reminders'
                }
            },
        ],
        select_company_dialog: false,
        company_selected: null,
        drawer: true,
        accesses: null,
    }),
    computed: {
        ...mapGetters({
            get_user: 'auth/get_user',
            get_companies: 'itineraryV2/get_companies',
            get_selected_company: 'auth/get_selected_company'
        }),
    },
    async mounted() {
        if (this.get_selected_company.id == null) {
            this.openSelectCompanyDialog()
        }
        this.accesses = this.get_user.accesses.map(access => access.company_id)

        this.runRandomTimeout()
        this.random_number_2 = this.randomNumber(0, 5)
    },
    methods: {
        randomNumber(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min
        },
        runRandomTimeout() {
            if (this.increment < 10) {
                this.increment += 1
                this.random_number = this.randomNumber(5000, 25000)

                setTimeout(this.runRandomTimeout, this.random_number)
            }
        },
        openSelectCompanyDialog() {
            this.select_company_dialog = true
        },
        closeSelectCompanyDialog() {
            this.select_company_dialog = false
        },
        selectCompany() {
            if (this.company_selected == null)
                return
            this.$store.dispatch('auth/set_selected_company', this.company_selected)
            this.closeSelectCompanyDialog()
            this.$router.go()
        },
        async logout() {
            await this.$axios.post('/logout')
            this.$store.commit('auth/set_empty_data')
            this.$router.push({ name: 'Login' })
        }
    }
}
</script>
<style scoped>
.chicken {
    position: fixed;
    bottom: 0px;
    left: 190px;
    z-index: 3;
    animation: moveRight 130s linear infinite;
}

.animal {
    position: fixed;
    bottom: 0px;
    left: 190px;
    z-index: 3;
    animation: moveRight 130s linear infinite;
}

:deep(.chicken .v-image__image) {
    transform: scaleX(-1);
}

@keyframes moveRight {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(1920px);
    }
}
</style>