<template>
    <v-container>
        <v-dialog v-model="create_note_dialog" persistent max-width="600">
            <v-card>
                <v-card-title>Add Note</v-card-title>
                <v-card-text>
                    <wysiwyg v-model="new_note" />
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeCreateNoteDialog()" text>Cancel</v-btn>
                    <v-btn @click="createNote()" color="primary">Create</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="update_note_dialog" persistent max-width="600">
            <v-card v-if="selected_note">
                <v-card-title>Update Note</v-card-title>
                <v-card-text>
                    <wysiwyg v-model="selected_note.note" />
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeUpdateNoteDialog()" text>Cancel</v-btn>
                    <v-btn @click="updateNote()" color="primary">Update</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="delete_note_dialog" persistent max-width="600">
            <v-card>
                <v-card-title>
                    Are you sure you want to delete this note?
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeDeleteNoteDialog()" text>Cancel</v-btn>
                    <v-btn @click="deleteNote()" color="red" dark>Remove</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'CreateNoteDialog',
    components: {
    },
    props: [
        'create_note_dialog', 'update_note_dialog', 'delete_note_dialog', 'itinerary', 'selected_note'
    ],
    data: () => ({
        new_note: null,
    }),
    async mounted() {
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_user: 'auth/get_user',
        }),
    },
    methods: {
        closeCreateNoteDialog() {
            this.$emit('closeCreateNoteDialog')
            this.new_note = null
        },
        async createNote() {
            if (this.new_note == null || this.new_note == '') {
                this.$toast.error('Please enter a note')
                return
            }
            let payload = {
                itinerary_id: this.itinerary.id,
                note: this.new_note,
                created_by: this.get_user.id,
            }
            await this.$axios.post('v2/itineraries/create_itinerary_note', payload)
                .then(({ data }) => {
                    this.$toast.success(data.message)
                    this.closeCreateNoteDialog()
                    this.$emit('refreshItinerary')
                })
        },
        closeUpdateNoteDialog() {
            this.$emit('closeUpdateNoteDialog')
        },
        async updateNote() {
            let payload = {
                note: this.selected_note.note,
            }
            await this.$axios.post(`v2/itineraries/update_itinerary_note/${this.selected_note.id}`, payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeUpdateNoteDialog()
                        this.$emit('refreshItinerary')
                    }
                })
        },
        closeDeleteNoteDialog() {
            this.$emit('closeDeleteNoteDialog')
        },
        async deleteNote() {
            await this.$axios.delete(`v2/itineraries/delete_itinerary_note/${this.selected_note.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeDeleteNoteDialog()
                        this.$emit('refreshItinerary')
                    }
                })
        },
    },
}
</script>