<template>
    <v-container fluid>
        <v-card elevation="0">
            <v-card-title class="align-start">
                <v-btn @click="refreshBookings(true, true)" rounded color="primary" class="mx-2">
                    <v-icon class="mr-2">mdi-refresh</v-icon>
                    Refresh
                </v-btn>
                <v-spacer />
                <small>Shift bookings</small>
                <v-btn @click="shiftBookings('up')" rounded color="primary" class="mx-2" :disabled="can_shift == false">
                    <v-icon class="mr-2">mdi-chevron-up</v-icon>
                    Up
                </v-btn>
                <v-btn @click="shiftBookings('down')" rounded color="primary" class="mx-2"
                    :disabled="can_shift == false">
                    <v-icon class="mr-2">mdi-chevron-down</v-icon>
                    Down
                </v-btn>
            </v-card-title>
            <v-skeleton-loader type="table" v-if="loading_bookings" />
            <v-card-text v-else
                style="color:initial!important;font-size:initial!important;letter-spacing:initial!important;">
                <draggable v-model="days_with_preview" group="days" :disabled="true" style="width:100%;">
                    <v-col cols="12" v-for="(day, day_index) in days_with_preview" :key="'day' + day_index"
                        :data-day-index="day_index" style="padding:0px;">
                        <div class="px-3">
                            <strong>{{ $date(day.date).format('dddd, MMM D, YYYY') }}</strong>
                            <v-menu right bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="mx-2" icon color="primary" v-bind="attrs" v-on="on">
                                        <v-icon size="35">mdi-plus</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item @click="openSearchSupplierDialog(day)">
                                        <v-list-item-title>
                                            <v-icon class="mr-2">mdi-semantic-web</v-icon>
                                            Search Suppliers
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="openCreateQuickBookingDialog(day)">
                                        <v-list-item-title>
                                            <v-icon class="mr-2">mdi-semantic-web</v-icon>
                                            Create Quick Booking
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="openDeleteBookingsDialog(day)">
                                        <v-list-item-title>
                                            <v-icon class="mr-2">mdi-trash-can</v-icon>
                                            Clear Day
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <v-divider class="mb-1 mt-2" style="background-color: grey;"></v-divider>
                            <v-row class="build-content">
                                <v-col cols="11" class="d-flex">
                                    <v-btn fab disabled large
                                        style="cursor:initial;background-color:black!important;color:white!important;"
                                        class="mt-2">
                                        <div>
                                            <div>Day</div>
                                            <div>{{ day_index + 1 }}</div>
                                        </div>
                                    </v-btn>
                                    <draggable v-model="day.bookings" v-bind="dragOptions"
                                        :group="{ name: 'bookings', pull: true, put: true }" :disabled="drag"
                                        :sort="false" @start="onDragStart($event, day_index)"
                                        @end="onDragEnd($event, day_index)" style="width:100%;">
                                        <transition-group type="transition" class="d-flex flex-wrap">
                                            <div v-for="(booking, booking_index) in day.bookings"
                                                :key="'booking' + booking_index" class="mx-1 my-2"
                                                style="width:170px!important;">
                                                <div class="drag-handle">
                                                    <v-btn @click="openUpdateBookingDialog(day, booking)" class="mx-2"
                                                        :disabled="booking.is_preview" fab large>
                                                        <v-icon v-if="booking.option && booking.option.product">
                                                            {{
                                                                booking.option.product.supplier.service_type_id == 1
                                                                    ? 'mdi-bed' :
                                                                    booking.option.product.supplier.service_type_id ==
                                                                        2
                                                                        ? 'mdi-walk' :
                                                                        booking.option.product.supplier.service_type_id
                                                                            == 3
                                                                            ? 'mdi-car-back' : 'mdi-plus-circle'
                                                            }}
                                                        </v-icon>
                                                    </v-btn>
                                                </div>
                                                <div v-if="booking.option.product.supplier"
                                                    :class="booking.is_preview ? 'preview ml-3 mt-2' : 'ml-3 mt-2'">
                                                    <div>
                                                        <b>{{ booking.option.product.supplier.supplier_name }}</b>
                                                        <small
                                                            v-if="booking.option.product.supplier.is_quick_supplier == 1"
                                                            class="ml-2">
                                                            (quick supplier)
                                                        </small>
                                                    </div>
                                                    <div>
                                                        <small><b>{{ booking.option.product.name }}</b></small>
                                                    </div>
                                                    <div>
                                                        <small>
                                                            <b style="font-size:10px;">
                                                                x{{ booking.count }}
                                                            </b>
                                                            <label>{{ booking.option.name }}</label>
                                                        </small>
                                                    </div>
                                                    <div style="font-size:14px;">
                                                        <small v-if="booking.check_in_time">
                                                            Check in:
                                                            {{
                                                                $date(booking.check_in_date + ' ' + booking.check_in_time)
                                                                    .format('h:mm A')
                                                            }}
                                                        </small>
                                                        <small v-if="booking.check_out_time">
                                                            <br>
                                                            Check out:
                                                            {{
                                                                $date(booking.check_out_date + ' ' + booking.check_out_time)
                                                                    .format('h:mm A')
                                                            }}
                                                        </small>
                                                        <small
                                                            v-if="booking.check_in_time == null && booking.check_out_time == null">No
                                                            time selected</small>
                                                    </div>
                                                    <div v-if="booking.option.product.supplier.warning_note">
                                                        <small class="tooltip" style="color:red;"><b>Supplier
                                                                warning</b>
                                                            <span class="tooltiptext">
                                                                <div
                                                                    v-html="booking.option.product.supplier.warning_note">
                                                                </div>
                                                            </span>
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </transition-group>
                                    </draggable>
                                </v-col>
                                <v-col cols="1">
                                    <template>
                                        <v-menu left bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn class="mx-2" fab large color="primary" v-bind="attrs" v-on="on">
                                                    <v-icon>mdi-plus</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item @click="openSearchSupplierDialog(day)">
                                                    <v-list-item-title>
                                                        <v-icon class="mr-2">mdi-semantic-web</v-icon>
                                                        Search Suppliers
                                                    </v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="openCreateQuickBookingDialog(day)">
                                                    <v-list-item-title>
                                                        <v-icon class="mr-2">mdi-semantic-web</v-icon>
                                                        Create Quick Booking
                                                    </v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="openDeleteBookingsDialog(day)">
                                                    <v-list-item-title>
                                                        <v-icon class="mr-2">mdi-trash-can</v-icon>
                                                        Clear Day
                                                    </v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </template>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                </draggable>
            </v-card-text>
        </v-card>
        <SearchSupplierDialog :search_supplier_dialog="search_supplier_dialog" :selected_day="selected_day"
            :itinerary="itinerary" @close="closeSearchSupplierDialog"
            @openCreateBookingDialog="openCreateBookingDialog" />
        <CreateBookingDialog :create_booking_dialog="create_booking_dialog" :itinerary="itinerary"
            :selected_options="selected_options" :selected_supplier="selected_supplier"
            @close="closeCreateBookingDialog" @createBooking="createBooking" />
        <CreateQuickBookingDialog :create_quick_booking_dialog="create_quick_booking_dialog" :itinerary="itinerary"
            :selected_day="selected_day" @close="closeCreateQuickBookingDialog" @refreshBookings="refreshBookings" />
        <UpdateBookingDialog v-if="selected_booking" :update_booking_dialog="update_booking_dialog"
            :itinerary="itinerary" :selected_booking="selected_booking" :product_options="product_options"
            @close="closeUpdateBookingDialog" @updateBooking="updateBooking"
            @openDeleteBookingDialog="openDeleteBookingDialog" />
        <DeleteBookingDialog :delete_booking_dialog="delete_booking_dialog" @close="closeDeleteBookingDialog"
            @deleteBooking="deleteBooking" />
        <DeleteBookingsDialog :delete_bookings_dialog="delete_bookings_dialog" @close="closeDeleteBookingsDialog"
            @deleteBookings="deleteBookings" />
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import SearchSupplierDialog from './dialogs/SearchSupplierDialog.vue'
import CreateBookingDialog from './dialogs/CreateBookingDialog.vue'
import CreateQuickBookingDialog from './dialogs/CreateQuickBookingDialog.vue'
import UpdateBookingDialog from './dialogs/UpdateBookingDialog.vue'
import DeleteBookingDialog from './dialogs/DeleteBookingDialog.vue'
import DeleteBookingsDialog from './dialogs/DeleteBookingsDialog.vue'
export default {
    name: 'BuildComponent',
    components: {
        draggable,
        SearchSupplierDialog,
        CreateBookingDialog,
        CreateQuickBookingDialog,
        UpdateBookingDialog,
        DeleteBookingDialog,
        DeleteBookingsDialog,
    },
    props: [
        'itinerary', 'loading_bookings', 'days'
    ],
    data() {
        return {
            days_with_preview: [],
            search_supplier_dialog: false,
            create_booking_dialog: false,
            create_quick_booking_dialog: false,
            update_booking_dialog: false,
            delete_booking_dialog: false,
            delete_bookings_dialog: false,
            selected_day: null,
            selected_booking: null,
            selected_options: [],
            selected_supplier: null,
            quick_supplier: null,
            can_shift: true,
            dragged_booking: null,
            drag: false,
            product_options: null,
        }
    },
    async mounted() {
        this.getDaysWithPreview()
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_supplier_types: 'itineraryV2/get_supplier_types',
            get_product_types: 'itineraryV2/get_product_types',
        }),
        dragOptions() {
            return {
                animation: 200,
                group: 'bookings',
                disabled: false,
                ghostClass: 'ghost',
                handle: '.drag-handle',
            }
        },
    },
    methods: {
        async refreshBookings(message = false, success = false) {
            this.$emit('refreshBookings', success)
            if (message) {
                this.$toast.info('Refreshing...')
            }
        },
        async getDaysWithPreview() {
            this.days_with_preview = []
            let sort_order = [5, 3, 1, 2, 4, 6]
            let preview_days = JSON.parse(JSON.stringify(this.days))
            preview_days.forEach((day, index) => {
                day.bookings.forEach(booking => {
                    let days = this.$date(booking.check_out_date).diff(booking.check_in_date, 'day') + 1
                    if (days > 1 && booking.is_preview === undefined) {
                        let preview_booking = Object.assign({}, booking)
                        preview_booking.is_preview = true
                        for (let i = 0; i < days - 1; i++) {
                            if (preview_days[index + i + 1]) {
                                preview_days[index + i + 1].bookings.unshift(preview_booking)
                            }
                        }
                    }
                })
                day.bookings.sort((a, b) => (sort_order.indexOf(a.option.product.supplier.service_type_id) > sort_order.indexOf(b.option.product.supplier.service_type_id))
                    ? 1 : ((sort_order.indexOf(b.option.product.supplier.service_type_id) > sort_order.indexOf(a.option.product.supplier.service_type_id)) ? -1 : 0))
            })
            this.days_with_preview = preview_days.filter(x => x.date != null)
            this.drag = false
        },
        onDragStart(event, old_index) {
            this.dragged_booking = this.days_with_preview[old_index].bookings[event.oldIndex]
            this.days_with_preview.forEach((day, index) => {
                day.bookings.forEach((booking, booking_index) => {
                    if (booking.is_preview && booking.id == this.dragged_booking.id) {
                        this.days_with_preview[index].bookings.splice(booking_index, 1)
                    }
                })
            })
        },
        onDragEnd(event, old_index) {
            this.drag = true
            let targetElement = event.to
            while (targetElement && !targetElement.getAttribute('data-day-index')) {
                targetElement = targetElement.parentElement
            }
            const targetDayIndex = targetElement.getAttribute('data-day-index')
            const dayIndex = parseInt(targetDayIndex, 10)
            if (dayIndex == old_index) {
                this.drag = false
                this.getDaysWithPreview()
            }
            else {
                this.$toast.info('Please wait...')
                let target_day = this.days_with_preview[dayIndex]
                let difference = this.$date(target_day.date).diff(this.$date(this.dragged_booking.check_in_date), 'day')
                let updated_dragged_booking = {
                    id: this.dragged_booking.id,
                    check_in_date: target_day.date,
                    check_out_date: this.$date(this.dragged_booking.check_out_date).add(difference, 'day').format('YYYY-MM-DD'),
                    count: this.dragged_booking.count,
                }
                this.updateBooking(updated_dragged_booking, false)
                this.$emit('onDragEnd')
            }
        },
        async shiftBookings(direction) {
            this.can_shift = false
            this.$toast.info('Shifting bookings, please wait...')
            let payload = {
                direction: direction,
            }
            await this.$axios.post(`v2/itineraries/shift_bookings/${this.itinerary.id}`, payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.refreshBookings()
                        this.$toast.success('Shifted bookings successfully')
                        this.can_shift = true
                    }
                })
        },
        openSearchSupplierDialog(item) {
            this.search_supplier_dialog = true
            this.selected_day = item
        },
        closeSearchSupplierDialog() {
            this.search_supplier_dialog = false
            this.selected_day = null
        },
        openCreateBookingDialog(data) {
            this.create_booking_dialog = true
            this.selected_options = data.selected_options
            this.selected_supplier = data.selected_supplier
            this.selected_options.forEach(option => {
                option.check_in_date = this.selected_day.date
                option.check_out_date = this.selected_day.date
                option.count = 1
                if (this.selected_supplier.service_type_id == 1) {
                    option.check_out_date = this.$date(this.selected_day.date).add(1, 'day').format('YYYY-MM-DD')
                    option.check_in_time = '15:00'
                    option.check_out_time = '12:00'
                }
                if (this.selected_supplier.service_type_id == 2) {
                    option.check_out_date = this.selected_day.date
                    option.check_in_time = null
                    option.check_out_time = null
                }
                if (this.selected_supplier.service_type_id == 3) {
                    option.check_out_date = this.selected_day.date
                    option.check_in_time = '06:00'
                    option.check_out_time = '12:00'
                }
            })
        },
        closeCreateBookingDialog() {
            this.create_booking_dialog = false
            this.selected_options = []
            this.selected_supplier = null
        },
        async createBooking(selected_options) {
            let invalid_options = []
            selected_options.forEach(option => {
                if (this.$date(option.check_out_date).isBefore(this.$date(option.check_in_date))) {
                    invalid_options.push(option)
                    this.$toast.error('End date must be greater than or equal to the start date')
                    return
                }
            })
            if (invalid_options.length > 0)
                return
            let payload = {
                options: selected_options,
                selected_day: this.selected_day,
            }
            await this.$axios.post(`v2/itineraries/create_bookings/${this.selected_day.id}`, payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeCreateBookingDialog()
                        this.refreshBookings()
                    }
                })
        },
        openCreateQuickBookingDialog(item) {
            this.create_quick_booking_dialog = true
            this.selected_day = item
        },
        closeCreateQuickBookingDialog() {
            this.create_quick_booking_dialog = false
            this.selected_day = null
        },
        async openUpdateBookingDialog(day, booking) {
            await this.$axios.get(`v2/suppliers/get_options_for_product/${booking.option.product.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.product_options = data.data
                        this.update_booking_dialog = true
                        this.selected_day = day
                        this.selected_booking = booking
                    }
                })
        },
        closeUpdateBookingDialog() {
            this.update_booking_dialog = false
            this.selected_day = null
            this.selected_booking = null
            this.product_options = null
        },
        async updateBooking(updated_booking, success = true) {
            if (this.$date(updated_booking.check_out_date).isBefore(this.$date(updated_booking.check_in_date))) {
                this.$toast.error('End date must be greater than or equal to the start date')
                return
            }
            let payload = {
                ...updated_booking,
            }
            await this.$axios.post(`v2/itineraries/update_booking/${this.itinerary.id}/${updated_booking.id}`, payload)
                .then(({ data }) => {
                    if (data.response) {
                        if (success) {
                            this.$toast.success(data.message)
                        }
                        this.closeUpdateBookingDialog()
                        this.refreshBookings(false, false)
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        openDeleteBookingDialog(day, booking) {
            this.delete_booking_dialog = true
            if (day) {
                this.selected_day = day
            }
            if (booking) {
                this.selected_booking = booking
            }
        },
        closeDeleteBookingDialog() {
            this.delete_booking_dialog = false
        },
        async deleteBooking() {
            await this.$axios.delete(`v2/itineraries/delete_booking/${this.selected_booking.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeUpdateBookingDialog()
                        this.closeDeleteBookingDialog()
                        this.refreshBookings()
                    } else {
                        this.$toast.error(data.message)
                    }
                })
        },
        openDeleteBookingsDialog(item) {
            this.delete_bookings_dialog = true
            this.selected_day = item
        },
        closeDeleteBookingsDialog() {
            this.delete_bookings_dialog = false
            this.selected_day = null
        },
        async deleteBookings() {
            await this.$axios.post(`v2/itineraries/delete_bookings_for_day/${this.selected_day.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeDeleteBookingsDialog()
                        this.refreshBookings()
                    }
                })
        },
    },
}
</script>

<style scoped lang="scss">
.action-button {
    display: none;
}

.build-content:hover .action-button {
    display: inline-block;
}

.preview {
    color: grey;
}

/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted red;
    /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    min-width: 250px;
    background-color: lightgrey;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}

/* Tooltip container */
.tooltip-quick-supplier {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted red;
    /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip-quick-supplier .tooltiptext {
    visibility: hidden;
    min-width: 250px;
    background-color: lightgrey;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-quick-supplier:hover .tooltiptext {
    visibility: visible;
}
</style>