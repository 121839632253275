<template>
    <v-container fluid :class="isMobile() ? 'pa-0' : ''" id="preview-component">
        <v-skeleton-loader type="card" v-if="loading_bookings"></v-skeleton-loader>
        <v-card elevation="0" v-else>
            <v-card-title v-if="!shareable_link" style="margin-top:-8px;margin-left:-28px;margin-bottom:20px;">
                <v-toolbar style="position:fixed;z-index:10;width:100%;text-align:center;" elevation="0">
                    <v-btn @click="refreshBookings(true, true)" rounded color="primary" class="mx-2">
                        <v-icon class="mr-2">mdi-refresh</v-icon>
                        Refresh
                    </v-btn>
                    <v-btn text small color="primary" @click="show_cover = !show_cover" :disabled="printing">
                        <v-icon class="mr-2" v-if="show_cover">mdi-eye-outline</v-icon>
                        <v-icon class="mr-2" v-else>mdi-eye-closed</v-icon>
                        <label style="cursor:pointer;" v-if="!show_cover">Show Cover Page</label>
                        <label style="cursor:pointer;" v-else>Hide Cover Page</label>
                    </v-btn>
                    <v-btn text small color="primary" @click="show_pricing = !show_pricing">
                        <v-icon class="mr-2" v-if="show_pricing">mdi-eye-outline</v-icon>
                        <v-icon class="mr-2" v-else>mdi-eye-closed</v-icon>
                        <label style="cursor:pointer;" v-if="!show_pricing">Show Pricing</label>
                        <label style="cursor:pointer;" v-else>Hide Pricing</label>
                    </v-btn>
                    <v-btn text small color="primary" @click="show_outline = !show_outline">
                        <v-icon class="mr-2" v-if="show_outline">mdi-eye-outline</v-icon>
                        <v-icon class="mr-2" v-else>mdi-eye-closed</v-icon>
                        <label style="cursor:pointer;" v-if="!show_outline">Show Summary</label>
                        <label style="cursor:pointer;" v-else>Hide Summary</label>
                    </v-btn>
                    <v-btn text small color="primary" @click="show_itinerary = !show_itinerary">
                        <v-icon class="mr-2" v-if="show_itinerary">mdi-eye-outline</v-icon>
                        <v-icon class="mr-2" v-else>mdi-eye-closed</v-icon>
                        <label style="cursor:pointer;" v-if="!show_itinerary">Show Details</label>
                        <label style="cursor:pointer;" v-else>Hide Details</label>
                    </v-btn>
                    <v-btn text small color="primary" @click="show_supplier_name = !show_supplier_name">
                        <v-icon class="mr-2" v-if="show_supplier_name">mdi-eye-outline</v-icon>
                        <v-icon class="mr-2" v-else>mdi-eye-closed</v-icon>
                        <label style="cursor:pointer;" v-if="!show_supplier_name">Show Supplier Name</label>
                        <label style="cursor:pointer;" v-else>Hide Supplier Name</label>
                    </v-btn>
                    <v-btn text small color="primary" @click="show_product_name = !show_product_name">
                        <v-icon class="mr-2" v-if="show_product_name">mdi-eye-outline</v-icon>
                        <v-icon class="mr-2" v-else>mdi-eye-closed</v-icon>
                        <label style="cursor:pointer;" v-if="!show_product_name">Show Product Name</label>
                        <label style="cursor:pointer;" v-else>Hide Product Name</label>
                    </v-btn>
                    <v-btn text small color="primary" @click="show_option_name = !show_option_name">
                        <v-icon class="mr-2" v-if="show_option_name">mdi-eye-outline</v-icon>
                        <v-icon class="mr-2" v-else>mdi-eye-closed</v-icon>
                        <label style="cursor:pointer;" v-if="!show_option_name">Show Option Name</label>
                        <label style="cursor:pointer;" v-else>Hide Option Name</label>
                    </v-btn>
                    <v-btn v-if="itinerary.company_id == 5" text small color="primary"
                        @click="show_tee_times = !show_tee_times">
                        <v-icon class="mr-2" v-if="show_tee_times">mdi-eye-outline</v-icon>
                        <v-icon class="mr-2" v-else>mdi-eye-closed</v-icon>
                        <label style="cursor:pointer;" v-if="!show_tee_times">Show Tee Times</label>
                        <label style="cursor:pointer;" v-else>Hide Tee Times</label>
                    </v-btn>
                    <v-btn text small color="primary" @click="show_images = !show_images">
                        <v-icon class="mr-2" v-if="show_images">mdi-eye-outline</v-icon>
                        <v-icon class="mr-2" v-else>mdi-eye-closed</v-icon>
                        <label style="cursor:pointer;" v-if="!show_images">Show Images</label>
                        <label style="cursor:pointer;" v-else>Hide Images</label>
                    </v-btn>
                </v-toolbar>
            </v-card-title>
            <v-card-title v-if="!shareable_link" class="align-start">
                <v-btn @click="downloadContactList()" rounded color="primary" class="mx-2">
                    <v-icon class="mr-2">mdi-download</v-icon>
                    Contact List
                </v-btn>
                <v-btn @click="encodeBase62()" rounded color="primary" class="mx-2">
                    <v-icon class="mr-2">mdi-link</v-icon>
                    Shareable Link (old)
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="getBookingsWithBase64('pdf')" :disabled="printing" color="primary" rounded class="mx-2">
                    <v-icon class="mr-2">mdi-download</v-icon>
                    Print PDF
                </v-btn>
                <div class="text-center">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="getBookingsWithBase64('admin_history')" :disabled="printing" v-bind="attrs"
                                v-on="on" color="primary" rounded class="mx-2">
                                <v-icon class="mr-2">mdi-content-save</v-icon>
                                Save to History
                            </v-btn>
                        </template>
                        <small>
                            Save PDF of current itinerary to admin history.
                            <br>
                            Use for admin reference before making changes.
                        </small>
                    </v-tooltip>
                    <br>
                    <v-btn @click="openViewDocumentsDialog('admin itinerary')" rounded color="primary" class="ml-2 mt-3"
                        text>
                        View Admin History
                    </v-btn>
                </div>
                <div class="text-center">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="getBookingsWithBase64('client_history')" :disabled="printing" v-bind="attrs"
                                v-on="on" color="primary" rounded class="mx-2">
                                <v-icon class="mr-2">mdi-content-save</v-icon>
                                Publish to Client
                            </v-btn>
                        </template>
                        <small>
                            Save PDF of current itinerary to client history.
                            <br>
                            Client will be able to view most recently saved PDF.
                        </small>
                    </v-tooltip>
                    <br>
                    <v-btn @click="openViewDocumentsDialog('client itinerary')" rounded color="primary"
                        class="ml-2 mt-3" text>
                        View Publish History
                    </v-btn>
                    <br>
                    <v-btn @click="copyItineraryLink()"
                        :disabled="itinerary.documents.filter(x => x.type == 'client itinerary').length < 1" rounded
                        color="primary" class="ml-2 mt-3" text>
                        <v-icon class="mr-2">mdi-content-copy</v-icon>
                        Copy Itinerary Link
                    </v-btn>
                </div>
            </v-card-title>
            <v-card-text style="max-width:900px;margin-left:auto;margin-right:auto;" :class="isMobile() ? 'pa-0' : ''">
                <section class="pdf-chunk">
                    <v-app>
                        <v-card v-if="show_cover || shareable_link" elevation="0" style="border-radius:0px;">
                            <div :style="'align-content:center;height:116px;background-color:' + itinerary.company.primary_color"
                                class="px-12">
                                <v-img v-if="itinerary.company.base64_secondary_logo"
                                    :src="itinerary.company.base64_secondary_logo" eager width="300" height="110"
                                    contain class="mx-auto" />
                                <v-img v-else :src="itinerary.company.base64_logo" eager width="300" height="110"
                                    contain class="mx-auto" />
                            </div>
                            <v-img :src="itinerary.company.base64_cover_image" style="width:100%;" eager />
                            <div :style="(isMobile() ? '' : 'height:357px;') + 'background-color:' + itinerary.company.primary_color"
                                class="px-12 pt-10 pb-3 text-center">
                                <h1 class="kavivanar"
                                    :style="(isMobile() ? 'font-size:30px;padding:0px 0px;' : 'font-size:70px;padding:0px 20px;') + 'color:' + itinerary.company.secondary_color">
                                    {{ itinerary.company.cover_title }}
                                </h1>
                                <h6 class="mt-10" style="color:white;font-weight:normal;font-size:14px;">
                                    {{ itinerary.company.website }}
                                </h6>
                            </div>
                        </v-card>
                        <v-card v-if="show_cover || shareable_link" elevation="0" style="page-break-before:always;">
                            <div class="py-15" style="margin:0px 40px;">
                                <h1 class="playfair text-center mb-10"
                                    style="font-size:50px;font-weight:normal;line-height:0.9;color:#1C2C56;">
                                    welcome to
                                    <br>
                                    <label
                                        :style="(isMobile() ? 'font-size:50px;' : 'font-size:90px;') + 'font-weight:600;'">
                                        {{
                                            itinerary.company.base64_secondary_logo ? 'Dream Trips Ireland' :
                                                itinerary.company.company_name
                                        }}
                                    </label>
                                </h1>
                                <p class="poppins" style="color:#1C2C56;" :contenteditable="!shareable_link">
                                    Dear {{ itinerary.travellers[0] ? itinerary.travellers[0].first_name : '' }},
                                </p>
                                <div v-html="itinerary.company.cover_description" style="color:#1C2C56;" class="poppins"
                                    :contenteditable="!shareable_link">
                                </div>
                                <div :style="'page-break-inside:avoid;border-radius:8px;background-color:' + itinerary.company.primary_color"
                                    class="pa-5 mt-10">
                                    <div v-html="itinerary.company.cover_details" class="poppins" style="color:white;"
                                        :contenteditable="!shareable_link"></div>
                                </div>
                            </div>
                        </v-card>
                        <v-card elevation="0" style="border-radius:0px;page-break-before:always;">
                            <div v-if="show_cover"
                                :style="'align-content:center;height:116px;background-color:' + itinerary.company.primary_color"
                                class="px-12">
                                <v-img v-if="itinerary.company.base64_secondary_logo"
                                    :src="itinerary.company.base64_secondary_logo" width="300" height="110" contain
                                    eager class="mx-auto" />
                                <v-img v-else :src="itinerary.company.base64_logo" width="300" height="110" contain
                                    eager class="mx-auto" />
                            </div>
                            <div class="my-10" style="color:#1C2C56;">
                                <h1 style="font-weight: 700; font-size:24px;text-align:center;" class="lora">
                                    <v-icon color="#1C2C56" size="50">mdi-map-marker-radius-outline</v-icon>
                                    <label :contenteditable="!shareable_link">
                                        {{ itinerary.itinerary_name }}
                                    </label>
                                </h1>
                            </div>
                            <div style="margin: 0px 40px;">
                                <h5 class="inter"
                                    style="font-weight: 400; font-size: 14px;color:#4F4E4E;text-align:center;">
                                    {{ $date(itinerary.start_date).format('MMMM DD') }}
                                    -
                                    {{ $date(itinerary.end_date).format('MMMM DD, YYYY') }}
                                </h5>
                            </div>
                            <v-row v-if="show_pricing"
                                :style="(isMobile() ? 'padding: 10px 15px;font-size:12px;' : 'padding: 40px 60px;') + 'margin:0px 40px;border:dashed grey 2px;background-color:#FAFAFA;margin-top:10px;'"
                                class="lora" no-gutters>
                                <v-col cols="7" class="mb-2">
                                    <h3>TOTAL PRICE FOR THIS PACKAGE:</h3>
                                </v-col>
                                <v-col cols="5" class="mb-2">
                                    <h3>
                                        {{
                                            (itinerary.currency ? itinerary.currency.symbol : '')
                                            + formatNumber((gross))
                                        }}
                                    </h3>
                                </v-col>
                                <v-col cols="7" class="mb-2">
                                    <h3>PRICE PER PERSON:</h3>
                                </v-col>
                                <v-col cols="5" class="mb-2">
                                    <h3>
                                        {{
                                            (itinerary.currency ? itinerary.currency.symbol : '')
                                            + formatNumber((gross / itinerary.travellers_number))
                                        }}
                                    </h3>
                                </v-col>
                                <v-col cols="7" class="mb-2">
                                    <h3>DEPOSIT:</h3>
                                </v-col>
                                <v-col cols="5" class="mb-2">
                                    <h3>
                                        {{
                                            (itinerary.currency ? itinerary.currency.symbol : '')
                                            + formatNumber((gross * itinerary.deposit_percent))
                                        }}
                                    </h3>
                                </v-col>
                                <v-col cols="7">
                                    <h3>BALANCE DUE:</h3>
                                </v-col>
                                <v-col cols="5">
                                    <h3>
                                        {{
                                            (itinerary.currency ? itinerary.currency.symbol : '')
                                            + formatNumber((gross - (gross * itinerary.deposit_percent)))
                                        }}
                                    </h3>
                                </v-col>
                            </v-row>
                        </v-card>
                        <div v-html="itinerary.comments" class="mt-7" style="margin:0px 40px;"
                            :contenteditable="!shareable_link">
                        </div>
                        <v-card v-if="show_outline" elevation="0"
                            :style="isMobile() ? 'margin:0px 10px;' : 'margin:0px 40px;'">
                            <div class="my-5 inter"
                                style="border-bottom:solid 1px #4F4E4E;padding-bottom:10px;font-size:26px;font-weight:600;color:#1C2C56;">
                                ITINERARY SUMMARY
                            </div>
                            <Summary :days="days_with_preview" :itinerary="itinerary"
                                :show_supplier_name="show_supplier_name" :show_product_name="show_product_name"
                                :show_option_name="show_option_name" :show_tee_times="show_tee_times"
                                :printing="printing" :shareable_link="shareable_link" @hideBooking="hideBooking" />
                        </v-card>
                    </v-app>
                </section>
                <v-card v-if="show_itinerary" elevation="0" style="page-break-before:always;">
                    <div class="inter pt-5"
                        style="margin:0px 40px;border-bottom:solid 1px #4F4E4E;padding-bottom:10px;font-size:26px;font-weight:600;color:#1C2C56;">
                        ITINERARY DETAILS
                    </div>
                    <Details :days="days_with_preview" :itinerary="itinerary" :show_supplier_name="show_supplier_name"
                        :show_product_name="show_product_name" :show_option_name="show_option_name"
                        :show_tee_times="show_tee_times" :shareable_link="shareable_link" :show_images="show_images" />
                </v-card>
                <section class="pdf-chunk">
                    <v-app>
                        <v-card elevation="0"
                            :style="'break-before:always;border-radius:0px;background-color:' + itinerary.company.primary_color">
                            <div style="align-content:center;height:115px;" class="px-12">
                                <v-img :src="itinerary.company.base64_logo" eager width="300" height="110" contain
                                    class="mx-auto" />
                            </div>
                            <div class="kavivanar"
                                :style="'margin-top:274px;margin-bottom:275px;font-size:50px;text-align:center;color:' + itinerary.company.secondary_color">
                                Thank you!
                            </div>
                            <div style="margin: 0px 40px;color:white;">
                                <b :style="'font-size:24px;color:' + itinerary.company.secondary_color">
                                    TERMS AND CONDITIONS
                                </b>
                                <br>
                                <b>{{ itinerary.company.company_name }} T & C</b>
                                <p>Explanation of Terms.</p>
                                <p>In these T&C 'we' and 'us' means {{ itinerary.company.company_name }}</p>
                                <p>
                                    'You' means the person purchasing the vacation who has requested a quote and has
                                    agreed to
                                    our T&C. You must be aware that on signing the booking form and or ticking the box
                                    on the quote
                                    request you are signing on behalf of every person travelling. If the booking form is
                                    signed
                                    on behalf of a limited company 'you' means that company. 'Event' means all aspects
                                    of a holiday
                                    booking with {{ itinerary.company.company_name }} be it an active, social or
                                    relaxation.
                                </p>
                                <p>
                                    <a :href="'https://www.' + itinerary.company.company_name.toLowerCase().split(' ').join('') + '.com/terms-and-conditions/'"
                                        style="color:white;">
                                        View Full Terms & Conditions
                                    </a>
                                </p>
                            </div>
                        </v-card>
                    </v-app>
                </section>
                <div v-if="!shareable_link" id="contact-list">
                    <section>
                        <v-app>
                            <v-card elevation="0">
                                <v-card-text style="text-align:center;color:black;">
                                    <h1>Contact List</h1>
                                    <br>
                                    <h2>{{ itinerary.itinerary_name }}</h2>
                                    <br>
                                    <h3>Itinerary Ref: {{ itinerary.internal_reference }},
                                        From: {{ $date(itinerary.start_date).format('D MMM, YYYY') }},
                                        To: {{ $date(itinerary.end_date).format('D MMM, YYYY') }},
                                        {{
                                            $date(itinerary.end_date).diff($date(itinerary.start_date),
                                                'DAY') + 1
                                        }} days
                                    </h3>
                                    <br>
                                    <div v-for="(type, contact_index) in contact_types" :key="contact_index">
                                        <h3 style="text-align:left;">{{ type }}</h3>
                                        <v-data-table :headers="headers" :items="bookings.filter((obj, index) => {
                                            return index === bookings.findIndex(o => obj.option.product.supplier.id === o.option.product.supplier.id)
                                                && obj.option.product.supplier.service_type_id == contact_index + 1
                                        })" hide-default-footer disable-pagination>
                                            <template v-slot:item="{ item }">
                                                <tr>
                                                    <td><b>{{ $date(item.check_in_date).format('DD MMM YY') }}</b></td>
                                                    <td><b>{{ $date(item.check_out_date).format('DD MMM YY') }}</b></td>
                                                    <td style="text-align:left;"><b>{{
                                                        item.option.product.supplier.supplier_name }}</b></td>
                                                    <td style="text-align:left;"><b>{{
                                                        item.option.product.supplier.address
                                                            }}</b></td>
                                                    <td style="text-align:left;"><b>{{
                                                        item.option.product.supplier.phone
                                                            }}</b>
                                                    </td>
                                                </tr>
                                            </template>
                                        </v-data-table>
                                        <br>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-app>
                    </section>
                </div>
            </v-card-text>
        </v-card>
        <ViewDocumentsDialog :view_documents_dialog="view_documents_dialog" :itinerary="itinerary"
            :document_type="document_type" @close="closeViewDocumentsDialog" />
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import Summary from './components/Summary.vue'
import Details from './components/Details.vue'
import ViewDocumentsDialog from '../dialogs/ViewDocumentsDialog.vue'
import html2pdf from 'html2pdf.js'
import { PDFDocument } from 'pdf-lib'
export default {
    components: {
        Summary,
        Details,
        ViewDocumentsDialog,
    },
    props: [
        'itinerary', 'loading_bookings', 'days', 'bookings', 'conversion', 'net', 'gross', 'shareable_link'
    ],
    data() {
        return {
            app_url: null,
            client_app_url: null,
            days_with_preview: [],
            days_with_base64: [],
            printing: false,
            show_images: true,
            show_cover: false,
            show_pricing: true,
            show_outline: true,
            show_itinerary: true,
            show_supplier_name: true,
            show_product_name: false,
            show_option_name: false,
            show_tee_times: false,
            group_accommodation_dates: true,
            items: [
                'Outline'
            ],
            tab: null,
            headers: [
                { text: 'Date in', width: '13%' },
                { text: 'Date out', width: '13%' },
                { text: 'Supplier' },
                { text: 'Address' },
                { text: 'Phone' },
            ],
            contact_types: ['Accommodation', 'Activity', 'Rental Car'],
            bookings_to_hide: [],
            view_documents_dialog: false,
            document_type: null,
        }
    },
    async mounted() {
        this.app_url = process.env.VUE_APP_URL
        this.client_app_url = process.env.VUE_APP_CLIENT_URL
        this.getDaysWithPreview(this.days)
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_user: 'auth/get_user',
        }),
    },
    methods: {
        async refreshBookings(message = false, success = false) {
            this.$emit('refreshBookings', success)
            if (message) {
                this.$toast.info('Refreshing...')
            }
            this.bookings_to_hide = []
            this.printing = false
        },
        formatNumber(number) {
            return new Intl.NumberFormat('en-US', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            }).format(number)
        },
        async getDaysWithPreview(selected_days) {
            this.days_with_preview = []
            let sort_order = [5, 3, 1, 2, 4, 6]
            let preview_days = JSON.parse(JSON.stringify(this.days))
            if (selected_days != null) {
                preview_days = JSON.parse(JSON.stringify(selected_days))
            }
            preview_days.forEach((day, index) => {
                day.bookings.forEach(booking => {
                    let days = this.$date(booking.check_out_date).diff(booking.check_in_date, 'day') + 1
                    // Show the preview booking if accommodation and if not check out date of accommodation
                    if (days > 1 && booking.is_preview === undefined && booking.option.product.supplier.service_type_id == 1) {
                        let preview_booking = Object.assign({}, booking)
                        preview_booking.is_preview = true
                        for (let i = 0; i < days - 1; i++) {
                            if (preview_days[index + i + 1] && booking.check_out_date != preview_days[index + i + 1].date) {
                                preview_days[index + i + 1].bookings.unshift(preview_booking)
                            }
                        }
                    }
                    if (
                        booking.is_preview === undefined
                        && booking.option.product.supplier.service_type_id == 3
                        && preview_days[preview_days.length - 1].bookings.length == 0
                        && preview_days[0].bookings.filter(x => x.check_out_date == preview_days[preview_days.length - 1].date).length > 0
                    ) {
                        let preview_booking = Object.assign({}, booking)
                        preview_booking.is_preview = true
                        preview_days[preview_days.length - 1].bookings.unshift(preview_booking)
                    }
                })
                day.bookings.sort((a, b) => (sort_order.indexOf(a.option.product.supplier.service_type_id) > sort_order.indexOf(b.option.product.supplier.service_type_id))
                    ? 1 : ((sort_order.indexOf(b.option.product.supplier.service_type_id) > sort_order.indexOf(a.option.product.supplier.service_type_id)) ? -1 : 0))
            })
            this.days_with_preview = preview_days.filter(x => x.date != null)
            this.bookings_to_hide.forEach(to_hide => {
                this.days_with_preview[to_hide.day_index].bookings.splice(to_hide.booking_index, 1)
            })
        },
        hideBooking(to_hide) {
            this.bookings_to_hide.push(to_hide)
            this.days_with_preview[to_hide.day_index].bookings.splice(to_hide.booking_index, 1)
        },
        async openPreviewLink() {
            if (this.itinerary.encrypted_id == null) {
                await this.$axios.post(`v2/itineraries/update_itinerary_encrypted_id/${this.itinerary.id}`)
                    .then(({ data }) => {
                        if (data.response) {
                            this.$router.push({ name: 'View Itinerary', params: { id: data.data.encrypted_id } })
                        }
                    })
            }
            else {
                this.$router.push({ name: 'View Itinerary', params: { id: this.itinerary.encrypted_id } })
            }
        },
        async getBookingsWithBase64(mode = 'pdf') {
            this.show_cover = true
            this.printing = true
            if (this.show_images) {
                let message = 'Please wait...'
                if (mode == 'pdf') {
                    message = 'Preparing to print PDF, please wait...'
                }
                else if (mode == 'admin_history') {
                    message = 'Preparing to save to admin history, please wait...'
                }
                else if (mode == 'client_history') {
                    message = 'Preparing to publish to client, please wait...'
                }
                const toastId = this.$toast.info(message, {
                    timeout: false,
                    closeOnClick: false,
                    draggable: false
                })
                await this.$axios.get(`v2/itineraries/get_bookings_with_base64/${this.itinerary.id}`)
                    .then(({ data }) => {
                        if (data.response) {
                            this.days_with_base64 = data.days
                            this.getDaysWithPreview(this.days_with_base64)
                            setTimeout(() => {
                                this.generateAndMergePDFs(mode)
                                this.$toast.dismiss(toastId)
                            }, 1000)
                        }
                    })
            } else {
                setTimeout(() => {
                    this.generateAndMergePDFs(mode)
                }, 1000)
            }
        },
        async generateAndMergePDFs(mode = 'pdf') {
            const chunks = document.querySelectorAll('#preview-component .pdf-chunk')
            const totalChunks = chunks.length
            const pdfPromises = []

            const baseOptions = {
                margin: [0, 0, 0, 0],
                filename: 'temp.pdf',
                image: { type: 'jpeg' },
                html2canvas: { scale: 1.25, useCORS: true },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
                pagebreak: { mode: ['css'] },
            }

            const toastId = this.$toast.info(`Processing part 1 of ${totalChunks}...`, {
                timeout: false,
                closeOnClick: false,
                draggable: false
            })

            // Generate separate PDFs for each chunk
            for (let i = 0; i < totalChunks; i++) {
                this.$toast.update(toastId, {
                    content: `Processing part ${i + 1} of ${totalChunks}...`
                })

                const chunk = chunks[i]
                const pdfBlob = await html2pdf().set(baseOptions).from(chunk).outputPdf('blob')
                pdfPromises.push(pdfBlob)
            }
            const pdfBlobs = await Promise.all(pdfPromises)

            this.$toast.update(toastId, {
                content: 'Saving...'
            })

            // Merge all generated PDFs
            const mergedBlob = await this.mergePDFs(pdfBlobs)

            if (mode == 'pdf') {
                // Download the merged PDF
                const url = URL.createObjectURL(mergedBlob)
                const link = document.createElement('a')
                link.href = url
                link.download = this.itinerary.itinerary_name + '.pdf'
                link.click()
                URL.revokeObjectURL(url)

                this.printing = false
                this.$toast.dismiss(toastId)
                this.$toast.success('Printed successfully')
            }
            else if (mode == 'admin_history') {
                // Save to admin history
                const formData = new FormData()
                formData.append('itinerary_id', this.itinerary.id)
                formData.append('type', 'admin itinerary')
                formData.append('file', mergedBlob, this.itinerary.itinerary_name + ' Admin Preview.pdf')

                this.$axios.post('v2/itineraries/create_itinerary_document', formData)
                    .then(({ data }) => {
                        if (data.response) {
                            this.$toast.dismiss(toastId)
                            this.$toast.success('Saved itinerary to admin history successfully')

                            this.printing = false

                            this.$emit('refreshItinerary')
                        }
                        else {
                            this.$toast.error(data.message)
                        }
                    })
            }
            else if (mode == 'client_history') {
                // Publish to client history
                const formData = new FormData()
                formData.append('itinerary_id', this.itinerary.id)
                formData.append('type', 'client itinerary')
                formData.append('file', mergedBlob, this.itinerary.itinerary_name + ' Client Preview.pdf')

                this.$axios.post('v2/itineraries/create_itinerary_document', formData)
                    .then(({ data }) => {
                        if (data.response) {
                            this.$toast.dismiss(toastId)
                            this.$toast.success('Published to client successfully')

                            this.printing = false

                            this.$emit('refreshItinerary')
                        }
                        else {
                            this.$toast.error(data.message)
                        }
                    })
            }
        },
        async mergePDFs(pdfBlobs) {
            const mergedPdf = await PDFDocument.create()

            for (const pdfBlob of pdfBlobs) {
                const pdfBytes = await pdfBlob.arrayBuffer()
                const pdf = await PDFDocument.load(pdfBytes)

                const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices())
                copiedPages.forEach((page) => mergedPdf.addPage(page))
            }

            const mergedPdfBytes = await mergedPdf.save()
            return new Blob([mergedPdfBytes], { type: 'application/pdf' })
        },
        openViewDocumentsDialog(type) {
            this.view_documents_dialog = true
            this.document_type = type
        },
        closeViewDocumentsDialog() {
            this.view_documents_dialog = false
            this.document_type = null
        },
        // printItineraryLandscape() {
        //     this.$toast.info('Printing...', { timeout: 10000 })
        //     const opt = {
        //         margin: 0,
        //         filename: this.itinerary.itinerary_name + '.pdf',
        //         image: { type: 'jpeg' },
        //         html2canvas: { scale: 1.3, useCORS: true },
        //         jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
        //     }
        //     html2pdf().set(opt).from(document.getElementById('itinerary-preview')).save()
        // },
        // printItineraryPortrait() {
        //     this.printing = true
        //     this.$toast.info('Printing...', { timeout: 10000 })
        //     const opt = {
        //         margin: [0, 0, 0, 0],
        //         filename: this.itinerary.itinerary_name + '.pdf',
        //         image: { type: 'jpeg' },
        //         html2canvas: { scale: 1.25, useCORS: true },
        //         jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        //         pagebreak: { mode: ['css'] },
        //     }
        //     html2pdf().set(opt).from(document.getElementById('itinerary-preview')).save()
        // },
        downloadContactList() {
            const opt = {
                margin: 0.1,
                filename: this.itinerary.itinerary_name + '-Contact List.pdf',
                image: { type: 'png', quality: 1 },
                html2canvas: { scale: 1.2, useCORS: true },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
            }
            html2pdf().set(opt).from(document.getElementById('contact-list')).save()
        },
        encodeBase62() {
            const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
            let result = ''
            let num = parseInt(this.itinerary.id + '' + this.itinerary.id, 10)

            while (num > 0) {
                result = chars[num % 62] + result
                num = Math.floor(num / 62)
            }

            window.open(this.app_url + 'preview_itinerary/' + result)
        },
        copyItineraryLink() {
            const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
            let result = ''
            let num = parseInt(this.itinerary.id + '' + this.itinerary.id, 10)

            while (num > 0) {
                result = chars[num % 62] + result
                num = Math.floor(num / 62)
            }

            navigator.clipboard.writeText(this.client_app_url + 'itinerary/' + result)
            this.$toast.success('Copied to clipboard')
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
    },
    watch: {
    }
}
</script>

<style scoped lang="scss">
:deep(tr),
:deep(h3),
:deep(h2),
:deep(h1) {
    page-break-inside: avoid;
}

:deep(th) {
    font-weight: 800 !important;
}

:deep(button) {
    text-transform: unset !important;
}

:deep(.v-input__slot label),
:deep(.v-input--selection-controls__input i) {
    color: #1976D2;
    font-size: 14px;
}

:deep(.v-toolbar),
:deep(.v-toolbar__content) {
    height: 40px !important;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:opsz@14..32&family=Kavivanar&family=Lora&family=Playfair:opsz@5..1200&family=Poppins&display=swap');

.kavivanar {
    font-family: 'Kavivanar', serif !important;
}

.playfair {
    font-family: 'Playfair', serif !important;
}

.poppins {
    font-family: 'Poppins', sans-serif !important;
}

.lora {
    font-family: 'Lora', serif !important;
}

.inter {
    font-family: 'Inter', sans-serif !important;
}

// @import url('https://fonts.googleapis.com/css2?family=Lexend:wght@500&display=swap');

// #itinerary-preview h1,
// #itinerary-preview div,
// #itinerary-preview b {
//     font-family: 'Lexend', sans-serif !important;
// }</style>