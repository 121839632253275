<template>
    <v-dialog :value="traveller_email_dialog" persistent max-width="900px">
        <v-card>
            <v-card-title>
                <label v-if="traveller_email_type == 'rooming-list'">
                    Send Rooming List Request
                </label>
                <label v-else-if="traveller_email_type == 'flight-details-escorted'">
                    Send Flight Details (ES) Request
                </label>
                <label v-else-if="traveller_email_type == 'flight-details-self-drive'">
                    Send Flight Details (SD) Request
                </label>
                <v-spacer />

                <v-btn @click="close()" icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-card>
                    <v-card-title>
                        <div class="d-flex align-center" style="width:100%;">
                            <v-text-field v-model="email_to_send.traveller_emails" label="Traveller Email"
                                @click.stop />
                            <v-text-field v-model="email_to_send.subject" label="Subject" @click.stop class="ml-5">
                            </v-text-field>
                        </div>
                        <div class="d-flex align-center" style="width:50%;">
                            <v-text-field v-model="email_to_send.cc_emails" label="CC Email To" @click.stop />
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <div v-if="traveller_email_type == 'rooming-list'" style="margin-bottom: 10px;">
                            <v-btn @click="addTableRow()" rounded outlined color="primary" text class="mr-3">Add
                                Row</v-btn>
                            <v-btn @click="removeTableRow()" rounded outlined color="primary" text>Remove Row</v-btn>
                        </div>
                        <wysiwyg v-model="email_to_send.content" />
                    </v-card-text>
                </v-card>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn @click="close()" rounded text class="px-5">
                    Cancel
                </v-btn>
                <v-btn @click="sendTravellerEmails()" color="primary" rounded class="px-5">
                    Send {{ email_to_send.length }} email<label v-if="email_to_send.length > 1">s</label>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    props: [
        'traveller_email_dialog', 'traveller_email_type', 'email_to_send'
    ],
    data() {
        return {
        }
    },
    mounted() {
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
        })
    },
    methods: {
        close() {
            this.$emit('close')
        },
        sendTravellerEmails() {
            this.$emit('sendTravellerEmails', this.email_to_send)
        },
        addTableRow() {
            const newRow = '<tr><td style="border:solid lightgrey 1px;">-</td><td style="border:solid lightgrey 1px;"></td><td style="border:solid lightgrey 1px;"></td></tr>'
            const tableEndIndex = this.email_to_send.content.lastIndexOf('</table>')
            if (tableEndIndex !== -1) {
                this.email_to_send.content =
                    this.email_to_send.content.slice(0, tableEndIndex) +
                    newRow +
                    this.email_to_send.content.slice(tableEndIndex)
            }
        },
        removeTableRow() {
            const tableMatch = this.email_to_send.content.match(/<table[^>]*>[\s\S]*?<\/table>/)
            if (!tableMatch) return

            const fullTable = tableMatch[0]
            const rows = fullTable.match(/<tr>[\s\S]*?<\/tr>/g)
            if (!rows || rows.length <= 1) return // Don't delete header row or if only one row exists

            const newTable = fullTable.replace(rows[rows.length - 1], '')
            this.email_to_send.content = this.email_to_send.content.replace(fullTable, newTable)
        },
    },
}
</script>

<style scoped lang='scss'></style>