<template>
  <v-app>
    <v-main>
      <v-snackbar v-model="mSnackbar.show" bottom="bottom" multi-line="multi-line" :timeout="4000" :vertical="true">
        {{ mSnackbar.message }}<br />
        <v-btn v-if="mSnackbar.button" @click="sendotp" color="primary">Click here to send out new OTP</v-btn>
      </v-snackbar>
      <component :is="layout">
        <router-view />
      </component>
      <v-dialog :value="!is_updated" width="700">
        <v-card>
          <v-card-title>
            New system update, please wait for the page to refresh...
          </v-card-title>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>

const defaultLayout = 'default'
import { mapGetters } from 'vuex'
export default {
  name: 'App',
  computed: {
    layout() {
      return (this.$route.meta.layout || defaultLayout) + '-layout'
    },
    ...mapGetters({
      mSnackbar: 'auth/mSnackbar',
      get_product_types: 'itineraryV2/get_product_types',
      get_user: 'auth/get_user',
    })
  },
  components: {
  },
  async mounted() {
    this.checkIfUserUpdated()
  },
  created() {
  },
  data: () => ({
    is_updated: true,
  }),
  methods: {
    async checkIfUserUpdated() {
      if (this.get_user.id == null)
        return
      await this.$axios.get(`v2/users/get_user/${this.get_user.id}`)
        .then(({ data }) => {
          if (data.response) {
            this.$store.dispatch('auth/set_user', data.data)
            this.is_updated = data.data.is_updated
            if (this.get_user.is_updated == 0) {
              this.getAllStatuses()
            }
          }
        })
    },
    async getAllStatuses() {
      await this.$axios.get('v2/itineraries/get_statuses')
        .then(({ data }) => {
          if (data.response) {
            this.$store.dispatch('itineraryV2/set_companies', data.data.companies)
            this.$store.dispatch('itineraryV2/set_statuses', data.data.statuses)
            this.$store.dispatch('itineraryV2/set_templates', data.data.templates)
            this.$store.dispatch('itineraryV2/set_tour_types', data.data.tour_types)
            this.$store.dispatch('itineraryV2/set_invoice_statuses', data.data.invoice_statuses)
            this.$store.dispatch('itineraryV2/set_stages', data.data.stages)
            this.$store.dispatch('itineraryV2/set_supplier_types', data.data.supplier_types)
            this.$store.dispatch('itineraryV2/set_product_types', data.data.product_types)
            this.$store.dispatch('itineraryV2/set_booking_statuses', data.data.booking_statuses)
            this.$store.dispatch('itineraryV2/set_booking_payment_statuses', data.data.booking_payment_statuses)
            this.$store.dispatch('itineraryV2/set_currencies', data.data.currencies)
            this.$store.dispatch('itineraryV2/set_user_roles', data.data.user_roles)
            this.$store.dispatch('itineraryV2/set_travel_statuses', data.data.travel_statuses)
            this.updateUser()
          }
        })
    },
    async updateUser() {
      this.get_user.is_updated = 1
      let payload = {
        ...this.get_user,
      }
      await this.$axios.post(`v2/users/update_user/${this.get_user.id}`, payload)
        .then(({ data }) => {
          if (data.response) {
            window.location.reload()
          }
          else {
            this.$toast.error(data.message)
          }
        })
    },
  },
};
</script>

<style>
@import '~vue-wysiwyg/dist/vueWysiwyg.css'
</style>