<template>
    <v-container fluid style="max-width:1400px;">
        <v-card elevation="0">
            <v-card-title class="mb-10">
                <v-btn @click="getPayments()" rounded color="primary" class="mx-2">
                    <v-icon class="mr-2">mdi-refresh</v-icon>
                    Refresh
                </v-btn>
                <v-spacer />
                <v-btn @click="copyPaymentLink()" rounded color="primary" class="mx-2">
                    <v-icon class="mr-2">mdi-content-copy</v-icon>
                    Copy Payment Link
                </v-btn>
                <download-csv :data="paymentsCsv">
                    <v-btn rounded color="primary" class="mx-2">
                        <v-icon class="mr-2">mdi-download</v-icon>
                        CSV
                    </v-btn>
                </download-csv>
                <v-btn @click="openCreatePaymentDialog()" rounded color="primary" class="mx-2">
                    Create Payment
                </v-btn>
            </v-card-title>
            <v-skeleton-loader v-if="loading_payments" type="table" />
            <v-card-text v-else>
                <v-data-table :items="payments" :headers="payment_headers" :sort-by.sync="sort_by"
                    :sort-desc.sync="sort_desc">
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>
                                {{ $dayjs(item.created_at).format('MMM D, YYYY') }}
                                <br>
                                {{ $dayjs(item.created_at).format('h:mm A') }}
                            </td>
                            <td>
                                {{ item.paid_date ? $dayjs(item.paid_date).format('MMM D, YYYY') : '' }}
                            </td>
                            <td>
                                <label v-if="item.name">
                                    {{ item.name }}
                                </label>
                                <v-tooltip v-else-if="item.paid_by_traveller" bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <label v-bind="attrs" v-on="on">
                                            {{
                                                item.paid_by_traveller.first_name + ' '
                                                + item.paid_by_traveller.last_name
                                            }}
                                        </label>
                                    </template>
                                    <small>TID: {{ item.paid_by_traveller_id }}</small>
                                </v-tooltip>
                                <v-tooltip v-else-if="item.paid_by_user" bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <label v-bind="attrs" v-on="on">
                                            Payment Taken By <br>
                                            {{ item.paid_by_user.name }}
                                        </label>
                                    </template>
                                    <small>UID: {{ item.paid_by_user_id }}</small>
                                </v-tooltip>
                            </td>
                            <td>
                                {{ item.type ? item.type.name : '' }}
                            </td>
                            <td>
                                {{ item.method }}
                            </td>
                            <td>
                                {{ item.vendor_tx_code }}
                            </td>
                            <td>
                                {{
                                    (item.currency ? item.currency.symbol : '')
                                    + formatNumber(item.amount)
                                }}
                            </td>
                            <td>
                                {{
                                    (item.currency ? item.currency.symbol : '')
                                    + formatNumber(item.surcharge)
                                }}
                            </td>
                            <td>
                                {{
                                    (item.currency ? item.currency.symbol : '')
                                    + formatNumber(item.tip_amount)
                                }}
                            </td>
                            <td>
                                <b>
                                    {{
                                        (item.currency ? item.currency.symbol : '')
                                        + formatNumber(item.total_amount)
                                    }}
                                </b>
                            </td>
                            <td>
                                <div class="d-flex">
                                    <v-btn v-if="!item.paid_by_user" @click="openViewPaymentDialog(item)" icon>
                                        <v-icon>mdi-magnify</v-icon>
                                    </v-btn>
                                    <v-btn v-if="item.paid_by_user" @click="openUpdatePaymentDialog(item)" icon>
                                        <v-icon>mdi-pen</v-icon>
                                    </v-btn>
                                    <v-btn v-if="item.paid_by_user" @click="openDeletePaymentDialog(item)" icon>
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </div>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
                <div style="font-size:14px;" class="mt-10">
                    <div class="d-flex">
                        <h3 style="color:black;" class="mt-2">Payment Breakdown</h3>
                        <v-text-field v-model="amount_to_pay" type="number" label="Preview Amount To Pay" dense outlined
                            class="ml-5" style="max-width:200px;" />
                        <v-btn @click="openTravellerEmailDialog('invoice')" v-if="itinerary.company_id == 2"
                            color="primary" class="ml-5 px-5" rounded :disabled="itinerary.travellers.length == 0 && amount_to_pay != null && amount_to_pay > 0">
                            Send Invoice
                        </v-btn>
                    </div>
                    <v-simple-table class="payment-breakdown">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th style="width:30%;"></th>
                                    <th>
                                        {{ itinerary.currency.name }}
                                    </th>
                                    <th>
                                        {{ itinerary.payment_currency.name }}
                                        (Rate: {{ paymentCurrencyRate }})
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Total Tour Price</td>
                                    <td>
                                        {{ itinerary.currency.symbol +
                                            formatNumber(gross) }}
                                    </td>
                                    <td>
                                        {{ itinerary.payment_currency.symbol +
                                            formatNumber(paymentCurrencyGross) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Total Paid</td>
                                    <td>
                                        {{ itinerary.currency.symbol +
                                            formatNumber(total_paid) }}
                                    </td>
                                    <td>
                                        {{ itinerary.payment_currency.symbol +
                                            formatNumber(paymentCurrencyTotalPaid) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Balance Due</td>
                                    <td>
                                        {{ itinerary.currency.symbol +
                                            formatNumber(balanceDue) }}
                                    </td>
                                    <td>
                                        {{ itinerary.payment_currency.symbol +
                                            formatNumber(paymentCurrencyBalanceDue) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        Selected Amount
                                    </td>
                                    <td>
                                        {{
                                            itinerary.currency.symbol
                                            + formatNumber(amount_to_pay)
                                        }}
                                    </td>
                                    <td>
                                        {{
                                            itinerary.payment_currency.symbol
                                            + formatNumber(amount_to_pay * paymentCurrencyRate)
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Payment Processing Fee
                                        ({{ itinerary.company.surcharge_percent * 100 }}%)
                                    </td>
                                    <td>
                                        {{
                                            itinerary.currency.symbol
                                            + formatNumber(amount_to_pay * itinerary.company.surcharge_percent)
                                        }}
                                    </td>
                                    <td>
                                        {{
                                            itinerary.payment_currency.symbol
                                            + formatNumber((amount_to_pay * itinerary.company.surcharge_percent) *
                                                paymentCurrencyRate)
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Amount To Pay</b></td>
                                    <td>
                                        <b>
                                            {{
                                                itinerary.currency.symbol
                                                + formatNumber(parseFloat(amount_to_pay)
                                                    + (amount_to_pay * itinerary.company.surcharge_percent))
                                            }}
                                        </b>
                                    </td>
                                    <td>
                                        {{ itinerary.payment_currency.symbol
                                            + formatNumber((parseFloat(amount_to_pay)
                                                + (amount_to_pay * itinerary.company.surcharge_percent))
                                                * paymentCurrencyRate) }}
                                    </td>
                                </tr>
                                <tr v-if="itinerary.currency_id != itinerary.payment_currency_id">
                                    <td>{{ itinerary.payment_currency.code }} Conversion Rate</td>
                                    <td>
                                        {{ formatNumber(rateWithBuffer, 4) }}
                                    </td>
                                    <td></td>
                                </tr>
                                <tr v-if="itinerary.currency_id != itinerary.payment_currency_id">
                                    <td><b>Total</b></td>
                                    <td>
                                        <b>
                                            {{ itinerary.payment_currency.symbol
                                                + formatNumber((parseFloat(amount_to_pay) + (amount_to_pay *
                                                    itinerary.company.surcharge_percent)) * rateWithBuffer) }}
                                        </b>
                                    </td>
                                    <td>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <br><br>
                </div>
            </v-card-text>
        </v-card>
        <ViewPaymentDialog v-if="selected_payment" :view_payment_dialog="view_payment_dialog" :itinerary="itinerary"
            :selected_payment="selected_payment" :payment_types="payment_types" @close="closeViewPaymentDialog"
            @updatePayment="updatePayment" />
        <CreatePaymentDialog :create_payment_dialog="create_payment_dialog" :itinerary="itinerary"
            :payment_types="payment_types" @close="closeCreatePaymentDialog" @createPayment="createPayment" />
        <UpdatePaymentDialog v-if="selected_payment" :update_payment_dialog="update_payment_dialog"
            :itinerary="itinerary" :selected_payment="selected_payment" :payment_types="payment_types"
            @close="closeUpdatePaymentDialog" @updatePayment="updatePayment" />
        <DeletePaymentDialog :delete_payment_dialog="delete_payment_dialog" @close="closeDeletePaymentDialog"
            @deletePayment="deletePayment" />

        <TravellerEmailDialog v-if="email_to_send" :traveller_email_dialog="traveller_email_dialog"
            :traveller_email_type="traveller_email_type" :email_to_send="email_to_send"
            @close="closeTravellerEmailDialog" @sendTravellerEmails="sendTravellerEmails" />
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import ViewPaymentDialog from './dialogs/ViewPaymentDialog.vue'
import CreatePaymentDialog from './dialogs/CreatePaymentDialog.vue'
import UpdatePaymentDialog from './dialogs/UpdatePaymentDialog.vue'
import DeletePaymentDialog from './dialogs/DeletePaymentDialog.vue'

import TravellerEmailDialog from '../../setup/dialogs/TravellerEmailDialog.vue'
export default {
    components: {
        ViewPaymentDialog,
        CreatePaymentDialog,
        UpdatePaymentDialog,
        DeletePaymentDialog,
        TravellerEmailDialog,
    },
    props: [
        'itinerary', 'net', 'gross', 'exchange_rates',
    ],
    data() {
        return {
            client_app_url: null,
            loading_payments: false,
            payments: [],
            payment_types: [],
            payment_headers: [
                { text: 'Created Date', value: 'created_at' },
                { text: 'Date Paid', value: 'paid_date' },
                { text: 'Paid By', value: 'paid_by_traveller_id' },
                { text: 'Type', value: 'payment_type_id' },
                { text: 'Payment Method', value: 'method' },
                { text: 'Transaction Ref', value: 'vendor_tx_code' },
                { text: 'Payment Amount', value: 'amount', width: '100px' },
                { text: 'Surcharge', value: 'surcharge', width: '100px' },
                { text: 'Tip to Agent', value: 'tip_amount', width: '100px' },
                { text: 'Total Amount', value: 'total_amount', width: '100px' },
                { text: '' },
            ],
            sort_by: 'created_at',
            sort_desc: true,
            view_payment_dialog: false,
            create_payment_dialog: false,
            update_payment_dialog: false,
            delete_payment_dialog: false,
            selected_payment: null,
            total_paid: 0,
            traveller_email_dialog: false,
            traveller_email_type: null,
            email_to_send: null,
            amount_to_pay: 0,
        }
    },
    async mounted() {
        this.client_app_url = process.env.VUE_APP_CLIENT_URL
        this.getPayments()
        this.getPaymentTypes()
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_user: 'auth/get_user',
        }),
        paymentCurrencyRate() {
            return this.exchange_rates[this.itinerary.currency.code][this.itinerary.payment_currency.code]
        },
        paymentCurrencyGross() {
            return this.gross * this.paymentCurrencyRate
        },
        paymentCurrencyTotalPaid() {
            return this.total_paid * this.paymentCurrencyRate
        },
        balanceDue() {
            return this.gross - this.total_paid
        },
        paymentCurrencyBalanceDue() {
            return this.paymentCurrencyGross - this.paymentCurrencyTotalPaid
        },
        finalPayment() {
            return this.balanceDue + (this.balanceDue * this.itinerary.company.surcharge_percent)
        },
        rateWithBuffer() {
            return this.paymentCurrencyRate / 100 * 103.5
        },
        paymentsCsv() {
            let csv_payments = []
            this.payments.forEach(payment => {
                let formatted_payment = {
                    PaymentID: payment.id,
                    CreatedDate: this.$date(payment.created_at).format('YYYY-MM-DD h:mm A'),
                    PaidDate: this.$date(payment.paid_date).format('YYYY-MM-DD'),
                    Company: this.itinerary.company.company_name,
                    Itinerary: this.itinerary.itinerary_name,
                    Name: payment.name,
                    PaidTo: payment.paid_to_traveller ? payment.paid_to_traveller.first_name + ' ' + payment.paid_to_traveller.last_name : '',
                    PaidBy: payment.paid_by_traveller ? payment.paid_by_traveller.first_name + ' ' + payment.paid_by_traveller.last_name : payment.paid_by_user ? 'Payment Taken By ' + payment.paid_by_user.name : '-',
                    Type: payment.type ? payment.type.name : '',
                    Method: payment.method,
                    Currency: payment.currency.code,
                    ItineraryAmount: payment.amount.toFixed(2),
                    Surcharge: payment.surcharge.toFixed(2),
                    TipToAgent: payment.tip_amount.toFixed(2),
                    TotalAmount: payment.total_amount.toFixed(2),
                    TransactionRef: payment.vendor_tx_code,
                }
                csv_payments.push(formatted_payment)
            })
            return csv_payments
        },
        base64ItineraryId() {
            const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
            let result = ''
            let num = parseInt(this.itinerary.id + '' + this.itinerary.id, 10)

            while (num > 0) {
                result = chars[num % 62] + result
                num = Math.floor(num / 62)
            }
            return result
        },
    },
    methods: {
        async refreshBookings(message = false) {
            this.$emit('refreshBookings')
            if (message) {
                this.$toast.info('Refreshing...')
            }
        },
        async getPayments() {
            this.loading_payments = true
            let payload = {
                itinerary_id: this.itinerary.id,
            }
            await this.$axios.get('v2/payments/get_payments', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.payments = data.data
                        this.total_paid = 0
                        this.payments.forEach(payment => {
                            let converted_amount = payment.amount * this.exchange_rates[payment.currency.code][this.itinerary.currency.code]
                            this.total_paid += converted_amount
                        })

                        this.amount_to_pay = this.balanceDue.toFixed(2)
                        this.loading_payments = false
                    }
                })
        },
        async getPaymentTypes() {
            let payload = {
                is_public: 1,
            }
            await this.$axios.get('v2/payments/get_payment_types', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.payment_types = data.data
                    }
                })
        },
        openViewPaymentDialog(item) {
            this.view_payment_dialog = true
            this.selected_payment = item
        },
        closeViewPaymentDialog() {
            this.view_payment_dialog = false
            this.selected_payment = null
        },
        openCreatePaymentDialog() {
            this.create_payment_dialog = true
        },
        closeCreatePaymentDialog() {
            this.create_payment_dialog = false
        },
        async createPayment(new_payment) {
            await this.$axios.post('v2/payments/create_payment', new_payment)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.getPayments()
                        this.closeCreatePaymentDialog()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        openUpdatePaymentDialog(item) {
            this.update_payment_dialog = true
            this.selected_payment = item
        },
        closeUpdatePaymentDialog() {
            this.update_payment_dialog = false
            this.selected_payment = null
        },
        async updatePayment(updated_payment) {
            await this.$axios.post(`v2/payments/update_payment/${updated_payment.id}`, updated_payment)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.getPayments()
                        this.closeUpdatePaymentDialog()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        openDeletePaymentDialog(item) {
            this.delete_payment_dialog = true
            this.selected_payment = item
        },
        closeDeletePaymentDialog() {
            this.delete_payment_dialog = false
            this.selected_payment = null
        },
        async deletePayment() {
            await this.$axios.delete(`v2/payments/delete_payment/${this.selected_payment.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.getPayments()
                        this.closeDeletePaymentDialog()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        formatNumber(number, digit = 2) {
            return new Intl.NumberFormat('en-US', {
                maximumFractionDigits: digit,
                minimumFractionDigits: digit,
            }).format(number)
        },
        copyPaymentLink() {
            navigator.clipboard.writeText(this.client_app_url + 'payments/' + this.base64ItineraryId)
            this.$toast.success('Copied to clipboard')
        },

        openTravellerEmailDialog(type) {
            this.traveller_email_dialog = true
            this.traveller_email_type = type
            let all_traveller_emails = this.itinerary.travellers[0].email

            let email_content = ''
            let subject = ''
            let country = '____'
            if (this.itinerary.countries != null) {
                country = this.itinerary.countries[0]
            }

            let client_first_name = '____'
            let client_last_name = '____'
            client_first_name = this.itinerary.travellers[0].first_name
            client_last_name = this.itinerary.travellers[0].last_name ? ' ' + this.itinerary.travellers[0].last_name : ''

            if (type == 'invoice') {
                subject = this.itinerary.company.company_name + ' - Payment Invoice'
                email_content += '<p>Hi ' + client_first_name + client_last_name + ',<br><br>'
                email_content += 'I hope you are doing great. We are excited to welcome you to ' + country + ' for your upcoming trip.<br><br>'
                email_content += 'We have an outstanding balance of <b>' + this.itinerary.currency.symbol + this.formatNumber(parseFloat(this.amount_to_pay) + (this.amount_to_pay * this.itinerary.company.surcharge_percent)) + '</b><br><br></p>'

                email_content += '<table style="width:80%;border-spacing:0px!important;border:solid lightgrey 1px;">'
                email_content += '<tr><td style="border:solid lightgrey 1px;">Total Tour Price</td>'
                email_content += '<td style="border:solid lightgrey 1px;text-align:right;">' + this.itinerary.currency.symbol + this.formatNumber(this.gross) + '</td></tr>'
                email_content += '<tr><td style="border:solid lightgrey 1px;">Total Paid</td>'
                email_content += '<td style="border:solid lightgrey 1px;text-align:right;">' + this.formatNumber(this.total_paid) + '</td></tr>'
                // email_content += '<tr><td style="white-space:preserve;"> </td><td></td></tr>'
                email_content += '<tr><td style="border:solid lightgrey 1px;">Balance Due</td>'
                email_content += '<td style="border:solid lightgrey 1px;text-align:right;">' + this.itinerary.currency.symbol + this.formatNumber(this.balanceDue) + '</td></tr>'
                email_content += '<tr><td style="white-space:preserve;"> </td><td></td></tr>'
                email_content += '<tr><td style="border:solid lightgrey 1px;">Selected Amount</td>'
                email_content += '<td style="border:solid lightgrey 1px;text-align:right;">' + this.itinerary.currency.symbol + this.formatNumber(this.amount_to_pay) + '</td></tr>'
                email_content += '<tr><td style="border:solid lightgrey 1px;">Payment Processing Fee</td>'
                email_content += '<td style="border:solid lightgrey 1px;text-align:right;">' + this.itinerary.currency.symbol + this.formatNumber(this.amount_to_pay * this.itinerary.company.surcharge_percent) + '</td></tr>'
                email_content += '<tr><td style="border:solid lightgrey 1px;"><b>Amount To Pay</b></td>'
                email_content += '<td style="border:solid lightgrey 1px;text-align:right;"><b>' + this.itinerary.currency.symbol + this.formatNumber(parseFloat(this.amount_to_pay) + (this.amount_to_pay * this.itinerary.company.surcharge_percent)) + '</b></td></tr>'
                email_content += '<tr><td style="border:solid lightgrey 1px;">' + this.itinerary.payment_currency.code + ' Conversion Rate</td>'
                email_content += '<td style="border:solid lightgrey 1px;text-align:right;">' + this.itinerary.payment_currency.symbol + this.formatNumber(this.rateWithBuffer, 4) + '</td></tr>'
                email_content += '<tr><td style="border:solid lightgrey 1px;"><b>Total</b></td>'
                email_content += '<td style="border:solid lightgrey 1px;text-align:right;"><b>' + this.itinerary.payment_currency.symbol + this.formatNumber((parseFloat(this.amount_to_pay) + (this.amount_to_pay * this.itinerary.company.surcharge_percent)) * this.rateWithBuffer) + '</b></td></tr>'
                email_content += '</table><br>'
                email_content += '<p>The current euro to dollar conversion rate for international payments is ' + this.itinerary.payment_currency.symbol + this.formatNumber(this.rateWithBuffer, 4) + '<br><br>'
                email_content += "The most accurate Euro to dollar conversion rate can be found here on Bank of America's website or on Wells Fargo website. This will give you something to compare it against since the rate fluctuates daily.<br><br>"
                email_content += "The dollar has never been so strong against the euro which is amazing news for you. It's actually 20% cheaper to travel to " + country + " (Europe) now than it was in 2021.<br><br>"
                email_content += 'Here is a link to pay the outstanding payment.<br>'
                email_content += '<a href="' + this.client_app_url + 'payments/' + this.base64ItineraryId + '">' + this.client_app_url + 'payments/' + this.base64ItineraryId + '</a><br>'
                email_content += 'Please note, payment must be made by ____ date to confirm all final details.<br><br>'
                email_content += 'I have also included your most up to date itinerary and our bank details, if you wish to pay via bank transfer. The trip looks amazing btw.. :)<br><br>'
                email_content += 'Thank you,<br><br>'
                email_content += '<b>Accounts Department</b><br>'
                email_content += `<div style="display:flex;"><img src=${this.itinerary.company.company_logo} width="30%" style="padding-right:30px;object-fit:contain;" />`
                email_content += '<div>' + this.itinerary.company.accounts_email + '<br>' + this.itinerary.company.website
                if (this.itinerary.company_id == 3) {
                    email_content += '<br>Office Number: +353-1-9053955'
                }
                if (this.itinerary.company_id == 4) {
                    email_content += '<br>Toll Free (USA & Canada):1 800-686-5645;+353 1 912 5506'
                }
                if (this.itinerary.company_id == 5 || this.itinerary.company_id == 6) {
                    email_content += '<br>Office Number: 1800 686 5645'
                }
                email_content += '</div></div><br>'
                if (this.itinerary.company_id == 4) {
                    email_content += '<i>A sister company of <label style="color:green;">GoIrishTours</label></i>'
                }
                email_content += '</p>'
            }

            let cc_emails = ''
            if (this.itinerary.contributors.length > 0 && this.itinerary.contributors.filter(x => x.user.user_role_id == 2).length > 0) {
                cc_emails = this.itinerary.contributors.filter(x => x.user.user_role_id == 2)[0].user.email + ','
            }

            this.email_to_send = {
                itinerary_id: this.itinerary.id,
                traveller_id: this.itinerary.travellers[0].id,
                traveller_emails: all_traveller_emails,
                cc_emails: cc_emails,
                reservations_email: this.itinerary.company.email,
                subject: subject,
                content: email_content,
                type: type,
            }
        },
        closeTravellerEmailDialog() {
            this.traveller_email_dialog = false
        },
        async sendTravellerEmails(data) {
            if (data.content == null || data.content == '' || data.subject == null || data.subject == '' || data.traveller_emails == null || data.traveller_emails == '') {
                this.$toast.error('Please fill out all fields')
                return
            }
            await this.$axios.post('v2/itineraries/emails/send_traveller_emails', data)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeTravellerEmailDialog()
                    }
                })
        },
    },
}
</script>

<style scoped>
:deep(.payment-breakdown td) {
    height: 30px !important;
}
</style>