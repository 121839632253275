<template>
    <v-container fluid>
        <v-data-table id="payment-table" dense :headers="headers" :items="get_invoices" class="elevation-1"
            :sort-by.sync="sort_by" :sort-desc.sync="sortDesc">
            <template v-slot:item="{ item }">
                <tr style="height:70px;">
                    <td>
                        {{ $dayjs(item.created_at).format('MMM D, YYYY') }}
                        <br>
                        {{ $dayjs(item.created_at).format('h:mm A') }}
                    </td>
                    <td>{{ item.invoice_number }}</td>
                    <td>{{ item.payment_name }}</td>
                    <td>{{ item.payment_type }}</td>
                    <td>
                        <div v-if="item.due_date">
                            {{ $date(item.due_date).format('DD MMM YYYY') }}
                        </div>
                    </td>
                    <td>
                        <div v-if="item.amount_due">{{ itinerary.currency.symbol }}{{ item.amount_due *
                            ((item.service_charge / 100) + 1) |
                            formatNumber }}</div>
                    </td>
                    <td>
                        <div v-if="item.date_paid">
                            {{ $date(item.date_paid).format('DD MMM YYYY') }}
                        </div>
                    </td>
                    <td>{{ item.method }}</td>
                    <td>
                        <div v-if="item.amount_paid">{{ itinerary.currency.symbol }}{{ item.amount_paid }}</div>
                    </td>
                    <td>
                        <v-badge v-if="item.amount_paid == (item.amount_due * ((item.service_charge / 100) + 1))"
                            color="green">
                            <span slot="badge">Paid</span>
                        </v-badge>
                        <v-badge v-if="item.due_date < $date(new Date()).format('YYYY-MM-DD') &&
                            item.amount_paid < (item.amount_due * ((item.service_charge / 100) + 1))" color="red">
                            <span slot="badge">Overdue</span>
                        </v-badge>
                        <v-badge v-if="item.amount_paid > (item.amount_due * ((item.service_charge / 100) + 1))"
                            color="purple">
                            <span slot="badge">Overpaid</span>
                        </v-badge>
                    </td>
                    <td>
                        <v-menu right bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="openEditInvoice(item)">
                                    <v-list-item-title>
                                        Edit
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="deleteInvoice(item)">
                                    <v-list-item-title>
                                        Delete
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </td>
                </tr>
            </template>
            <template v-slot:no-data>
                <label class="float-left ma-5" style="font-weight: 700; font-size: large;">No record found!</label>
            </template>
        </v-data-table>
        <v-row class="my-5">
            <v-col cols="6">
                {{ get_invoices.length }} payments
            </v-col>
            <v-col cols="3">
                Itinerary price:
                <br>
                Total due:
                <br>
                Total paid:
                <br>
                Remaining balance:
            </v-col>
            <v-col cols="3">
                {{ itinerary.currency.symbol }}{{ gross | formatNumber }}
                <br>
                {{ itinerary.currency.symbol }}{{ get_total_due | formatNumber }}
                <br>
                {{ itinerary.currency.symbol }}{{ get_total_paid | formatNumber }}
                <br>
                {{ itinerary.currency.symbol }}{{ gross - get_total_paid | formatNumber }}
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    props: [
        'total_due', 'total_paid', 'itinerary', 'net', 'gross'
    ],
    data() {
        return {
            headers: [
                { text: 'Created Date', value: 'created_at' },
                { text: 'Invoice No.', value: 'invoice_number' },
                { text: 'Payment Name', value: 'payment_name' },
                { text: 'Type', value: 'payment_type' },
                { text: 'Due Date', value: 'due_date' },
                { text: 'Amount Due', value: 'amount_due' },
                { text: 'Date Paid', value: 'date_paid' },
                { text: 'Method', value: 'method' },
                { text: 'Amount Paid', value: 'amount_paid' },
                { text: 'Status', sortable: false },
                { text: '', sortable: false }
            ],
            total_items: [
                { text: 'Itinerary price', value: 0 },
                { text: 'Total due', value: 0 },
                { text: 'Total paid', value: 0 },
                { text: 'Remaining balance', value: 0 },
            ],
            payments: [],
            sort_by: 'created_at',
            sortDesc: true
        }
    },
    async mounted() {
        this.setTotals()
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_invoices: 'itinerary/get_invoices',
            get_total_due: 'itinerary/get_total_due',
            get_total_paid: 'itinerary/get_total_paid',
        }),
    },
    methods: {
        openEditInvoice(item) {
            this.$emit('openEditInvoice', item)
        },
        async deleteInvoice(item) {
            let payload = {
                id: item.id,
                itinerary_id: this.itinerary.id,
            }
            await this.$axios.post('/itinerary/payments/delete_invoice', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$store.commit('itinerary/set_invoices', data.data)
                        this.$store.commit('itinerary/set_total_due', data.total_due)
                        this.$store.commit('itinerary/set_total_paid', data.total_paid)
                        this.setTotals()
                    }
                })
        },
        async setTotals() {
            this.total_items[0].value = this.gross
            this.total_items[1].value = this.get_total_due
            this.total_items[2].value = this.get_total_paid
            this.total_items[3].value = this.total_items[0].value - this.total_items[2].value
        },
    },
    watch: {
    }
}
</script>

<style scoped lang="scss"></style>