<template>
    <v-container>
        <v-dialog v-model="view_email_dialog" persistent max-width="800">
            <v-card v-if="selected_email">
                <v-card-title>
                    View Email
                    <v-spacer />
                    <v-btn @click="close()" icon>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <h2>Sent Date:</h2> {{ $date(selected_email.created_at).format('MMM DD, YYYY h:m A') }}
                        </v-col>
                        <v-col cols="6">
                            <h2>Type:</h2> {{ selected_email.type }}
                        </v-col>
                        <v-col cols="6">
                            <h2>Recipient:</h2>
                            {{
                                selected_email.traveller_id ? 'Traveller' : selected_email.supplier_id ? 'Supplier' :
                                    'N/A'
                            }}
                            <br>
                            {{ selected_email.recipient_email }}
                            <br>
                            {{
                                selected_email.traveller ? selected_email.traveller.first_name + ' ' +
                                    selected_email.traveller.last_name
                                    : selected_email.supplier ? selected_email.supplier.supplier_name
                                        : ''
                            }}
                            <br>
                            {{
                                selected_email.traveller ? selected_email.mobile_no
                                    : selected_email.supplier ? selected_email.supplier.phone
                                        : ''
                            }}
                        </v-col>
                        <v-col cols="6">
                            <h2>Subject:</h2> {{ selected_email.subject }}
                        </v-col>
                        <v-col cols="12">
                            <h2>Content:</h2>
                            <br>
                            <div v-html="selected_email.content"
                                style="overflow-y:auto;max-height:400px;padding:10px;border:solid 1px lightgrey;">
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn @click="close()" text>Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    name: 'ViewEmailDialog',
    components: {
    },
    props: [
        'view_email_dialog', 'selected_email',
    ],
    data: () => ({
    }),
    async mounted() {
    },
    created() {
    },
    computed: {
    },
    methods: {
        close() {
            this.$emit('close')
        },
    },
}
</script>