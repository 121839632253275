<template>
    <v-container fluid>
        <v-card elevation="0">
            <v-card-title>
                <v-btn @click="getSentEmails()" rounded color="primary" class="mx-2">
                    <v-icon class="mr-2">mdi-refresh</v-icon>
                    Refresh
                </v-btn>
            </v-card-title>
            <v-skeleton-loader v-if="loading_sent_emails" type="table" />
            <v-card-text v-else class="mt-5">
                <div>
                    <v-btn @click="history_type = 'sent_emails'" rounded color="primary" class="mr-3"
                        :disabled="history_type == 'sent_emails'">Sent Email History</v-btn>
                </div>
                <v-data-table :items="sent_emails" :headers="sent_emails_header" :sort-by.sync="sort_by"
                    :sort-desc.sync="sort_desc">
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>
                                {{ $date(item.created_at).format('MMM DD, YYYY') }}
                                <br>
                                {{ $date(item.created_at).format('h:m A') }}
                            </td>
                            <td>
                                <b>
                                    {{ item.traveller_id ? 'Traveller' : item.supplier_id ? 'Supplier' : 'N/A' }}
                                </b>
                                <br>
                                {{ item.recipient_email }}
                                <br>
                                {{
                                    item.traveller ? item.traveller.first_name + ' ' + item.traveller.last_name
                                        : item.supplier ? item.supplier.supplier_name
                                            : ''
                                }}
                                <br>
                                {{
                                    item.traveller ? item.mobile_no
                                        : item.supplier ? item.supplier.phone
                                            : ''
                                }}
                            </td>
                            <td>
                                {{ item.type }}
                            </td>
                            <td>
                                {{ item.subject }}
                            </td>
                            <td>
                                <div v-html="item.content"
                                    style="overflow-y:auto;min-height:100px;max-height:250px;padding:10px;border-left:solid 1px lightgrey;">
                                </div>
                            </td>
                            <td>
                                <v-btn @click="openViewEmailDialog(item)" color="primary" rounded>
                                    View
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <ViewEmailDialog :view_email_dialog="view_email_dialog" :selected_email="selected_email"
            @close="closeViewEmailDialog" />
    </v-container>
</template>

<script>
import ViewEmailDialog from './dialogs/ViewEmailDialog.vue'
export default {
    name: 'HistoryComponent',
    components: {
        ViewEmailDialog,
    },
    props: [
        'itinerary',
    ],
    data: () => ({
        history_type: 'sent_emails',
        sent_emails_header: [
            { text: 'Sent Date', value: 'created_at', width: '10%', sortable: true },
            { text: 'Recipient', width: '15%', sortable: false },
            { text: 'Type', value: 'type', width: '1%', sortable: true },
            { text: 'Subject', width: '15%', sortable: false },
            { text: 'Content', width: '60%', sortable: false },
            { text: '', width: '1%', sortable: false },
        ],
        sort_by: 'created_at',
        sort_desc: true,
        loading_sent_emails: false,
        sent_emails: [],
        view_email_dialog: false,
        selected_email: null,
    }),
    async mounted() {
        this.getSentEmails()
    },
    created() {
    },
    computed: {
    },
    methods: {
        async getSentEmails() {
            this.loading_sent_emails = true
            let payload = {
                itinerary_id: this.itinerary.id,
            }
            await this.$axios.get('v2/itineraries/emails/get_sent_emails', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.sent_emails = data.data
                        this.loading_sent_emails = false
                    }
                })
        },
        openViewEmailDialog(item) {
            this.view_email_dialog = true
            this.selected_email = item
        },
        closeViewEmailDialog() {
            this.view_email_dialog = false
        },
    },
}
</script>