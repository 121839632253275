<template>
    <v-dialog :value="supplier_email_dialog" persistent max-width="900px">
        <v-card v-if="grouped_bookings">
            <v-card-title>
                <label v-if="supplier_email_type == 'booking'">
                    Send Booking Requests
                </label>
                <label v-else-if="supplier_email_type == 'cancellation'">
                    Send Cancellation Requests
                </label>
                <v-spacer />

                <v-btn @click="close()" icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-card v-for="(supplier_bookings, index) in updated_bookings" :key="index">
                    <v-list class="mb-5" dense two-line>
                        <v-list-group>
                            <template v-slot:activator>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <div class="d-flex align-center">
                                                <div style="width:50%;">
                                                    <v-text-field v-if="supplier_email_type == 'payment'"
                                                        v-model="supplier_bookings[0].option.product.supplier.accounts_email"
                                                        label="Supplier Accounts Email" @click.stop />
                                                    <v-text-field v-else-if="supplier_email_type == 'booking'
                                                        || supplier_email_type == 'cancellation'
                                                        || supplier_email_type == 'supplier-rooming-list'"
                                                        v-model="supplier_bookings[0].option.product.supplier.email"
                                                        label="Supplier Reservations Email" @click.stop />
                                                </div>
                                                <div style="width:50%;">
                                                    <v-text-field v-model="supplier_bookings[0].subject" label="Subject"
                                                        @click.stop class="ml-5" />
                                                </div>
                                            </div>
                                            <div class="d-flex align-center">
                                                <div style="width:50%;">
                                                    <b>
                                                        {{ supplier_bookings[0].option.product.supplier.supplier_name }}
                                                    </b>
                                                    <div v-for="(booking, booking_index) in supplier_bookings"
                                                        :key="'booking' + booking_index" class="my-3">
                                                        <div class="ml-3">{{ booking.option.product.name }}</div>
                                                        <div class="ml-3">
                                                            <b>x{{ booking.count }}</b>
                                                            {{ booking.option.name }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style="width:50%;">
                                                    <v-text-field v-model="supplier_bookings[0].cc_emails"
                                                        label="CC Email To" class="ml-5" @click.stop />
                                                </div>
                                            </div>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                            <v-list-item>
                                <div style="width:100%;">
                                    <div v-if="supplier_email_type == 'supplier-rooming-list'" style="margin-bottom: 10px;">
                                        <v-btn @click="addTableRow(index)" rounded outlined color="primary" text class="mr-3">
                                            Add Row</v-btn>
                                        <v-btn @click="removeTableRow(index)" rounded outlined color="primary" text>Remove Row</v-btn>
                                    </div>
                                    <wysiwyg v-model="supplier_bookings[0].email" />
                                </div>
                            </v-list-item>
                        </v-list-group>
                    </v-list>
                </v-card>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn @click="close()" rounded text class="px-5">
                    Cancel
                </v-btn>
                <v-btn @click="sendSupplierEmails()" color="primary" rounded class="px-5">
                    Send {{ updated_bookings.length }} email<label v-if="updated_bookings.length > 1">s</label>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    props: [
        'supplier_email_dialog', 'supplier_email_type', 'grouped_bookings'
    ],
    data() {
        return {
            updated_bookings: { ...this.grouped_bookings },
        }
    },
    mounted() {
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
        })
    },
    methods: {
        close() {
            this.$emit('close')
        },
        sendSupplierEmails() {
            this.$emit('sendSupplierEmails', this.updated_bookings)
        },
        addTableRow(index) {
            const newRow = '<tr><td style="border:solid lightgrey 1px;">-</td><td style="border:solid lightgrey 1px;"></td><td style="border:solid lightgrey 1px;"></td></tr>'
            const tableEndIndex = this.updated_bookings[index][0].email.lastIndexOf('</table><p></p>')
            if (tableEndIndex !== -1) {
                let updated_email =
                    this.updated_bookings[index][0].email.slice(0, tableEndIndex) +
                    newRow +
                    this.updated_bookings[index][0].email.slice(tableEndIndex)

                this.$set(this.updated_bookings[index], 0, { ...this.updated_bookings[index][0], email: updated_email })
            }
        },
        removeTableRow(index) {
            const tableMatch = this.updated_bookings[index][0].email.match(/<table[^>]*>[\s\S]*?<\/table>/)
            if (!tableMatch) return

            const fullTable = tableMatch[0]
            const rows = fullTable.match(/<tr>[\s\S]*?<\/tr>/g)
            if (!rows || rows.length <= 1) return

            const newTable = fullTable.replace(rows[rows.length - 1], '')
            
            this.$set(this.updated_bookings[index], 0, { ...this.updated_bookings[index][0], email: this.updated_bookings[index][0].email.replace(fullTable, newTable) })
        },
    },
}
</script>

<style scoped lang='scss'></style>