<template>
    <v-container fluid style="max-width:1500px;">
        <v-skeleton-loader type="table" v-if="loading_itinerary"></v-skeleton-loader>
        <v-row v-else>
            <v-col cols="6">
                <v-card>
                    <v-card-text>
                        <v-row no-gutters>
                            <v-col cols="12" class="px-1">
                                <v-text-field v-model="itinerary.itinerary_name" label="Itinerary name *"
                                    style="font-size:20px;" dense />
                            </v-col>
                            <v-col cols="6" class="px-1">
                                <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="5"
                                    :nudge-bottom="61" transition="scale-transition" min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="itinerary.start_date" label="Start date *" readonly
                                            v-bind="attrs" v-on="on" outlined dense />
                                    </template>
                                    <v-date-picker v-model="itinerary.start_date"
                                        @input="menu1 = false; date_changed = true;" />
                                </v-menu>
                            </v-col>
                            <v-col cols="6" class="px-1">
                                <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="5"
                                    :nudge-bottom="61" transition="scale-transition" min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="itinerary.end_date" label="End date *" readonly
                                            v-bind="attrs" v-on="on" outlined dense />
                                    </template>
                                    <v-date-picker v-model="itinerary.end_date"
                                        @input="menu2 = false; date_changed = true;" />
                                </v-menu>
                            </v-col>
                            <v-col cols="6" class="px-1">
                                <v-text-field v-model="itinerary.itinerary_number" label="Itinerary number" outlined
                                    dense />
                            </v-col>
                            <v-col cols="6" class="px-1">
                                <v-text-field v-model="itinerary.internal_reference" label="Internal reference" outlined
                                    dense />
                            </v-col>
                            <v-col cols="6" class="px-1">
                                <v-select :items="get_companies" v-model="itinerary.company_id" item-text="company_name"
                                    item-value="id" outlined dense label="Company" />
                            </v-col>
                            <v-col cols="6" class="px-1">
                                <v-text-field v-model="itinerary.travellers_number" label="Total # of travellers"
                                    outlined dense />
                            </v-col>
                            <v-col cols="6" class="px-1">
                                <v-select v-model="itinerary.tour_type_id" :items="get_tour_types" item-value="id"
                                    item-text="name" label="Tour Type" outlined dense />
                            </v-col>
                            <v-col cols="6" class="px-1">
                                <v-select v-model="itinerary.count_transpo_end_date"
                                    :items="[{ text: 'Yes', value: 1 }, { text: 'No', value: 0 }]" item-value="value"
                                    item-text="text" label="Count End Date on Transpo" outlined dense />
                            </v-col>
                            <v-col cols="6" class="px-1">
                                <v-combobox v-model="itinerary.countries" :items="available_countries" multiple chips
                                    label="Countries" outlined dense>
                                    <template v-slot:selection="{ attrs, item, parent, selected }">
                                        <v-chip v-bind="attrs" :input-value="selected" label small class="my-1">
                                            <span class="pr-2">
                                                {{ item }}
                                            </span>
                                            <v-icon @click="parent.selectItem(item)" small>
                                                $delete
                                            </v-icon>
                                        </v-chip>
                                    </template>
                                </v-combobox>
                            </v-col>
                            <v-col cols="6" class="px-1">
                                <v-combobox v-model="itinerary.res_action_required" :items="['Yes', 'No']"
                                    label="Res Action Required" outlined dense />
                            </v-col>
                            <v-col cols="12" class="px-1 pb-3 text-center">
                                <h5>Statuses</h5>
                            </v-col>
                            <v-col cols="6" class="px-1">
                                <div>
                                    <v-autocomplete v-model="itinerary.itinerary_status_id" :items="get_statuses"
                                        item-value="id" item-text="name" label="Itinerary Status" outlined
                                        dense></v-autocomplete>
                                </div>
                            </v-col>
                            <v-col cols="6" class="px-1">
                                <div>
                                    <v-autocomplete v-model="itinerary.itinerary_template_id" :items="get_templates"
                                        item-value="id" item-text="name" label="Template" outlined
                                        dense></v-autocomplete>
                                </div>
                            </v-col>
                            <v-col cols="6" class="px-1">
                                <v-select v-model="itinerary.stage_id" :items="get_stages" item-value="id"
                                    item-text="name" label="Booking Status" outlined dense />
                            </v-col>
                            <v-col cols="6" class="px-1">
                                <v-select v-model="itinerary.travel_status_id" :items="get_travel_statuses"
                                    item-value="id" item-text="name" label="Travel Status" outlined dense />
                            </v-col>
                            <v-col cols="12" class="px-1 pb-3 text-center">
                                <h5>Payments</h5>
                            </v-col>
                            <v-col cols="6" class="px-1">
                                <v-select v-model="itinerary.currency_id" :items="get_currencies" item-text="code"
                                    item-value="id" label="Itinerary Currency" outlined dense />
                            </v-col>
                            <v-col cols="6" class="px-1">
                                <v-select v-model="itinerary.payment_currency_id" :items="get_currencies"
                                    item-text="code" item-value="id" label="Payment Gateway Currency" outlined dense />
                            </v-col>
                            <v-col cols="6" class="px-1">
                                <v-select v-model="itinerary.invoice_status_id" :items="get_invoice_statuses"
                                    item-value="id" item-text="name" label="Invoice Stage" outlined dense />
                            </v-col>
                            <v-col cols="6" class="px-1">
                                <v-select v-model="itinerary.full_payment_enabled"
                                    :items="[{ name: 'Yes', value: 1 }, { name: 'No', value: 0 }]" item-text="name"
                                    item-value="value" label="Enable Full Payment" outlined dense />
                            </v-col>
                            <!-- <v-col cols="6" class="px-1">
                                <v-text-field v-model="itinerary.partial_payment_percent"
                                    label="Partial Payment Limit %" outlined dense />
                            </v-col> -->
                            <v-col cols="12" class="px-1 pb-3 text-center">
                                <h5>Airport Pickup Details</h5>
                            </v-col>
                            <v-col cols="6" class="px-1">
                                <v-text-field v-model="itinerary.driver_name" label="Driver Name" outlined dense />
                            </v-col>
                            <v-col cols="6" class="px-1">
                                <v-text-field v-model="itinerary.driver_phone" label="Driver Phone" outlined dense />
                            </v-col>
                            <v-col cols="6" class="px-1">
                                <v-text-field v-model="itinerary.meetup_point" label="Meetup Point" outlined dense />
                            </v-col>
                            <v-col cols="12" class="px-1 mb-2">
                                <v-expansion-panels v-model="panel">
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>
                                            Comments (to be shown on itinerary)
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <wysiwyg v-model="itinerary.comments" />
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="openDeleteItineraryDialog()" rounded color="red" class="px-5" text>
                            <v-icon>mdi-delete</v-icon>
                            Delete
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="duplicateItinerary()" rounded color="primary" text class="mx-8 px-5 py-2">
                            <b>Duplicate</b>
                        </v-btn>
                        <v-btn @click="updateItinerary()" v-if="!updating" rounded color="primary" class="px-8 py-6"
                            style="font-size:16px;">
                            <b>Update</b>
                        </v-btn>
                        <v-btn v-else disabled rounded color="primary" class="px-8 py-6" style="font-size:16px;">
                            <b>Please Wait</b>
                        </v-btn>
                    </v-card-actions>
                </v-card>
                <div class="d-flex mt-3" style="justify-content:space-between;">
                    <label>
                        <small>
                            <b>First Created At:</b>
                            {{ $date(itinerary.created_at).format('MMM DD, YYYY h:mm A') }}
                        </small>
                    </label>
                    <label>
                        <small>
                            <b>Last Updated At:</b>
                            {{ $date(itinerary.updated_at).format('MMM DD, YYYY h:mm A') }}
                        </small>
                    </label>
                </div>
            </v-col>
            <v-col cols="6">
                <v-card class="mb-5">
                    <v-card-title>
                        Assignees
                        <v-spacer></v-spacer>
                        <v-btn @click="openCreateContributorDialog()" color="primary" rounded>
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-row v-for="(contributor, index) in itinerary.contributors" :key="'contributor' + index"
                            class="my-3" style="border-top: solid 1px lightgrey;border-bottom:solid 1px lightgrey;">
                            <v-col cols="10">
                                <div v-if="contributor.user">
                                    <b>{{ contributor.user.name }}</b>
                                    <label class="access-label ml-5">
                                        <b>{{ contributor.user.role ? contributor.user.role.name : '' }}</b>
                                    </label>
                                    <br>
                                    {{ contributor.user.email }}
                                </div>
                            </v-col>
                            <v-col cols="2" style="text-align:right;">
                                <v-btn @click="openDeleteContributorDialog(contributor)" color="red" icon>
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card class="mb-5">
                    <v-card-title class="align-start">
                        <div>
                            Travellers
                            <br>
                            <div v-if="itinerary.company.login_enabled" style="font-size:14px;">
                                Password: <b>{{ itinerary.password }}</b>
                                <v-btn @click="copyPassword()" icon class="ml-5">
                                    <v-icon>mdi-content-copy</v-icon>
                                </v-btn>
                            </div>
                        </div>
                        <v-spacer></v-spacer>
                        <div class="text-center">
                            <v-menu>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="primary" v-bind="attrs" v-on="on" class="mr-2 px-5" rounded
                                        :disabled="selected_travellers.length == 0">
                                        Send Emails
                                        <v-icon>mdi-menu-down</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item @click="openTravellerEmailDialog('custom')">
                                        <v-list-item-title>
                                            Send Custom Email
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="openTravellerEmailDialog('rooming-list')">
                                        <v-list-item-title>
                                            Send Rooming List Request
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="openTravellerEmailDialog('flight-details-escorted')">
                                        <v-list-item-title>
                                            Send Flight Details (ES) Request
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="openTravellerEmailDialog('flight-details-self-drive')">
                                        <v-list-item-title>
                                            Send Flight Details (SD) Request
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                        <v-btn @click="openCreateTravellerDialog()" color="primary" rounded>
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-row v-for="(traveller, index) in itinerary.travellers" :key="'traveller' + index"
                            class="my-3" style="border-top: solid 1px lightgrey;border-bottom:solid 1px lightgrey;">
                            <v-col cols="9" class="d-flex">
                                <input v-if="traveller.is_organiser"
                                    :checked="selected_travellers.findIndex((existing_traveller) => existing_traveller.id == traveller.id) > -1 ? true : false"
                                    @change="selectTraveller(traveller)" type="checkbox" dense
                                    style="cursor:pointer;width:20px;height:20px;" class="mr-4 mt-3" />
                                <div>
                                    <b>
                                        <label>{{ traveller.first_name + ' ' }}</label>
                                        <label>{{ traveller.last_name }}</label>
                                        <label v-if="traveller.is_organiser" class="access-label ml-5">Organiser</label>
                                    </b>
                                    <br>
                                    <label>{{ traveller.email }}</label>
                                </div>
                            </v-col>
                            <v-col cols="3" style="text-align:right;">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn @click="setOrganiser(traveller)" v-bind="attrs" v-on="on" color="green"
                                            icon>
                                            <v-icon dark>mdi-account-check-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Set Organiser</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn @click="openUpdateTravellerDialog(traveller)" v-bind="attrs" v-on="on"
                                            color="primary" icon>
                                            <v-icon dark>mdi-pen</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Update Traveller</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn @click="openDeleteTravellerDialog(traveller)" v-bind="attrs" v-on="on"
                                            color="red" icon>
                                            <v-icon dark>mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Delete Traveller</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card class="mb-5">
                    <v-card-title>
                        Notes
                        <v-spacer></v-spacer>
                        <div class="d-flex align-center">
                            <small class="mr-4">Oldest first</small>
                            <v-switch v-model="sort_by_newest" inset></v-switch>
                            <small class="mr-2">Newest first</small>
                        </div>
                        <v-btn @click="openCreateNoteDialog()" color="primary" rounded>
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-row v-for="(note, index) in sortedNotes" :key="'note' + index" class="my-3"
                            style="max-height:350px;overflow-y:auto;overflow-x:hidden;border-top: solid 1px lightgrey;border-bottom:solid 1px lightgrey;">
                            <v-col cols="10">
                                <div v-if="note.creator">
                                    <b style="color:black;">{{ note.creator.name }}</b> —
                                    {{ $date(note.created_at).format('MMM DD, YYYY h:m A') }}
                                </div>
                                <div v-html="note.note"></div>
                            </v-col>
                            <v-col cols="2" style="text-align:right;">
                                <v-btn @click="openUpdateNoteDialog(note)" color="primary" icon>
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn @click="openDeleteNoteDialog(note)" color="red" icon>
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="delete_itinerary_dialog" persistent max-width="600">
            <v-card>
                <v-card-title>
                    Are you sure you want to delete this itinerary?
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeDeleteItineraryDialog()" text>
                        Cancel
                    </v-btn>
                    <v-btn @click="deleteItinerary()" text color="red">
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <ContributorDialog :create_contributor_dialog="create_contributor_dialog"
            :delete_contributor_dialog="delete_contributor_dialog" :itinerary="itinerary" :users="users"
            :selected_contributor="selected_contributor" @closeCreateContributorDialog="closeCreateContributorDialog"
            @closeDeleteContributorDialog="closeDeleteContributorDialog" @refreshItinerary="refreshItinerary" />
        <TravellerDialog :create_traveller_dialog="create_traveller_dialog"
            :update_traveller_dialog="update_traveller_dialog" :delete_traveller_dialog="delete_traveller_dialog"
            :itinerary="itinerary" :selected_traveller="selected_traveller"
            @closeCreateTravellerDialog="closeCreateTravellerDialog"
            @closeUpdateTravellerDialog="closeUpdateTravellerDialog"
            @closeDeleteTravellerDialog="closeDeleteTravellerDialog" @refreshItinerary="refreshItinerary" />
        <NoteDialog :create_note_dialog="create_note_dialog" :update_note_dialog="update_note_dialog"
            :delete_note_dialog="delete_note_dialog" :itinerary="itinerary" :selected_note="selected_note"
            @closeCreateNoteDialog="closeCreateNoteDialog" @closeUpdateNoteDialog="closeUpdateNoteDialog"
            @closeDeleteNoteDialog="closeDeleteNoteDialog" @refreshItinerary="refreshItinerary" />
        <TravellerEmailDialog v-if="email_to_send" :traveller_email_dialog="traveller_email_dialog"
            :traveller_email_type="traveller_email_type" :email_to_send="email_to_send"
            @close="closeTravellerEmailDialog" @sendTravellerEmails="sendTravellerEmails" />
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import ContributorDialog from './dialogs/ContributorDialog.vue'
import TravellerDialog from './dialogs/TravellerDialog.vue'
import NoteDialog from './dialogs/NoteDialog.vue'
import TravellerEmailDialog from './dialogs/TravellerEmailDialog'
export default {
    name: 'SetupComponent',
    components: {
        ContributorDialog, TravellerDialog, NoteDialog, TravellerEmailDialog,
    },
    props: [
        'loading_itinerary', 'itinerary'
    ],
    data: () => ({
        app_url: null,
        users: [],
        date_changed: false,
        menu1: false,
        menu2: false,
        delete_itinerary_dialog: false,
        create_contributor_dialog: false,
        selected_contributor: null,
        delete_contributor_dialog: false,
        create_traveller_dialog: false,
        selected_traveller: null,
        update_traveller_dialog: false,
        delete_traveller_dialog: false,
        create_note_dialog: false,
        selected_note: null,
        update_note_dialog: false,
        delete_note_dialog: false,
        sort_by_newest: true,
        panel: null,
        updating: false,
        available_countries: ['Ireland', 'Northern Ireland', 'Scotland', 'England', 'Iceland', 'Italy'],
        traveller_email_dialog: false,
        traveller_email_type: null,
        email_to_send: null,
        selected_travellers: [],
    }),
    async mounted() {
        this.app_url = process.env.VUE_APP_URL
        this.getUsers()
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_user: 'auth/get_user',
            get_selected_company: 'auth/get_selected_company',
            get_companies: 'itineraryV2/get_companies',
            get_statuses: 'itineraryV2/get_statuses',
            get_templates: 'itineraryV2/get_templates',
            get_tour_types: 'itineraryV2/get_tour_types',
            get_invoice_statuses: 'itineraryV2/get_invoice_statuses',
            get_stages: 'itineraryV2/get_stages',
            get_currencies: 'itineraryV2/get_currencies',
            get_travel_statuses: 'itineraryV2/get_travel_statuses',
        }),
        sortedNotes() {
            if (this.itinerary != null && this.itinerary.notes.length > 0) {
                if (this.sort_by_newest) {
                    let notes = [...this.itinerary.notes].sort(function (a, b) {
                        return b.id - a.id
                    })
                    return notes
                }
                else {
                    let notes = [...this.itinerary.notes].sort(function (a, b) {
                        return a.id - b.id
                    })
                    return notes
                }
            }
            return []
        },
    },
    methods: {
        async refreshItinerary(message = false, success = false) {
            this.$emit('refreshItinerary', success)
            if (message) {
                this.$toast.info('Refreshing...')
            }
        },
        async getUsers() {
            let payload = {
                company_id: this.get_selected_company.id,
            }
            await this.$axios.get('v2/users/get_users_by_company_access', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.users = data.data
                    }
                })
        },
        updateItinerary() {
            this.updating = true
            if (this.$date(this.itinerary.end_date).isBefore(this.$date(this.itinerary.start_date)) || this.$date(this.itinerary.end_date).isSame(this.$date(this.itinerary.start_date))) {
                this.$toast.error('End date must be greater than the start date')
                return
            }
            let payload = {
                ...this.itinerary,
            }
            this.$axios.post(`v2/itineraries/update_itinerary_setup/${this.itinerary.id}`, payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.$emit('refreshBookings', false)
                    } else {
                        this.$toast.error(data.message)
                    }
                    this.updating = false
                })
        },
        async duplicateItinerary() {
            this.$toast('Please wait...')
            await this.$axios.post(`v2/itineraries/duplicate_itinerary/${this.itinerary.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        let link = this.app_url + 'itinerary/update/' + data.data.id
                        let myJSX = (
                            <div>
                                <div>Itinerary duplicated successfully, click below to view</div>
                                <a href={link} target="_blank" style="margin-top:10px;color:white;font-weight:800;">View Duplicate Itinerary</a>
                            </div>
                        )
                        this.$toast.success(myJSX, { timeout: 10000, closeOnClick: false, draggable: false })
                    }
                })
        },
        openDeleteItineraryDialog() {
            this.delete_itinerary_dialog = true
        },
        closeDeleteItineraryDialog() {
            this.delete_itinerary_dialog = false
        },
        deleteItinerary() {
            this.$axios.delete(`v2/itineraries/delete_itinerary/${this.itinerary.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeDeleteItineraryDialog()
                        this.$router.push({ name: 'Itineraries' })
                    }
                })
        },
        openCreateContributorDialog() {
            this.create_contributor_dialog = true
        },
        closeCreateContributorDialog() {
            this.create_contributor_dialog = false
        },
        openDeleteContributorDialog(item) {
            this.delete_contributor_dialog = true
            this.selected_contributor = item
        },
        closeDeleteContributorDialog() {
            this.delete_contributor_dialog = false
            this.selected_contributor = null
        },
        openCreateTravellerDialog() {
            this.create_traveller_dialog = true
        },
        closeCreateTravellerDialog() {
            this.create_traveller_dialog = false
        },
        openUpdateTravellerDialog(item) {
            this.update_traveller_dialog = true
            this.selected_traveller = item
        },
        closeUpdateTravellerDialog() {
            this.update_traveller_dialog = false
            this.selected_traveller = null
        },
        openDeleteTravellerDialog(item) {
            this.delete_traveller_dialog = true
            this.selected_traveller = item
        },
        closeDeleteTravellerDialog() {
            this.delete_traveller_dialog = false
            this.selected_traveller = null
        },
        openCreateNoteDialog() {
            this.create_note_dialog = true
        },
        closeCreateNoteDialog() {
            this.create_note_dialog = false
        },
        openUpdateNoteDialog(item) {
            this.update_note_dialog = true
            this.selected_note = item
        },
        closeUpdateNoteDialog() {
            this.update_note_dialog = false
            this.selected_note = null
        },
        openDeleteNoteDialog(item) {
            this.delete_note_dialog = true
            this.selected_note = item
        },
        closeDeleteNoteDialog() {
            this.delete_note_dialog = false
            this.selected_note = null
        },
        copyPassword() {
            navigator.clipboard.writeText(this.itinerary.password)
            this.$toast.success('Copied to clipboard')
        },
        async setOrganiser(traveller) {
            await this.$axios.post(`v2/itineraries/update_itinerary_traveller_organiser/${traveller.id}/${this.itinerary.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.refreshItinerary()
                    }
                })
        },
        selectTraveller(traveller) {
            let index = this.selected_travellers.findIndex((item) => item.id == traveller.id)
            if (index == -1) {
                let pushed_traveller = {
                    id: traveller.id,
                    email: traveller.email,
                    first_name: traveller.first_name,
                    last_name: traveller.last_name,
                }
                this.selected_travellers.push(pushed_traveller)
            }
            else {
                this.selected_travellers.splice(index, 1)
            }
        },
        openTravellerEmailDialog(type) {
            this.traveller_email_dialog = true
            this.traveller_email_type = type
            let all_traveller_emails = ''
            this.selected_travellers.forEach(traveller => {
                all_traveller_emails += traveller.email + ','
            })

            let email_content = ''
            let subject = ''
            let country = '____'
            if (this.itinerary.countries != null) {
                country = this.itinerary.countries[0]
            }

            let client_first_name = '____'
            let client_last_name = '____'
            if (this.selected_travellers != null) {
                client_first_name = this.selected_travellers[0].first_name
                client_last_name = this.selected_travellers[0].last_name ? ' ' + this.selected_travellers[0].last_name : ''
            }

            if (type == 'custom') {
                email_content += '<br><br><br><br><br><br>Regards,<br>'
                email_content += '<b>Reservations Team</b><br>'
                email_content += `<div style="display:flex;"><img src=${this.itinerary.company.company_logo} width="30%" style="padding-right:30px;object-fit:contain;" />`
                email_content += '<div>' + this.itinerary.company.email + '<br>' + this.itinerary.company.website
                if (this.itinerary.company_id == 3) {
                    email_content += '<br>Office Number: +353-1-9053955'
                }
                if (this.itinerary.company_id == 4) {
                    email_content += '<br>Office Number: 1866 376 5645 | 01 213 516 9674<br>Dubai Silicon Oasis DDP Bldg. A1 Dubai UAE'
                }
                if (this.itinerary.company_id == 5 || this.itinerary.company_id == 6) {
                    email_content += '<br>Office Number: 1800 686 5645'
                }
                email_content += '</div></div><br>'
                if (this.itinerary.company_id == 4) {
                    email_content += '<i>A sister company of <label style="color:green;">GoIrishTours</label></i>'
                }
                email_content += '</p>'
            }
            else if (type == 'rooming-list') {
                subject = 'Request for Rooming List'
                email_content += '<p>Hi ' + client_first_name + client_last_name + ',<br><br>'
                email_content += 'Greetings!<br><br>As we prepare for your upcoming trip, we kindly request your assistance in providing us with the rooming list for all members of your group. This will ensure a smooth check-in process and allow us to arrange comfortable accommodation tailored to your needs.<br><br>'
                email_content += 'To help us with the arrangements, please complete the following template with the required names per room:<br><br><b>Rooming List:</b></p>'
                email_content += '<table style="width:100%;border-spacing:0px!important;"><tr>'
                email_content += '<th style="text-align:left;padding:10px;border:solid lightgrey 1px;">Room Type</th>'
                email_content += '<th style="text-align:left;padding:10px;border:solid lightgrey 1px;">Guest Name/s</th>'
                email_content += '<th style="text-align:left;padding:10px;border:solid lightgrey 1px;">Special Requests/Notes</th></tr>'
                email_content += '<tr><td style="border:solid lightgrey 1px;">-</td><td style="border:solid lightgrey 1px;"></td><td style="border:solid lightgrey 1px;"></td></tr>'
                email_content += '<tr><td style="border:solid lightgrey 1px;">-</td><td style="border:solid lightgrey 1px;"></td><td style="border:solid lightgrey 1px;"></td></tr></table><br>'
                email_content += '<p>We kindly ask you to submit the completed rooming list no later than 15 days before your arrival. This will give us ample time to finalize all arrangements and ensure a seamless experience for everyone.<br><br>'
                email_content += 'Thank you for your attention to this matter.<br><br><br>'
                email_content += 'Regards,<br>'
                email_content += '<b>Reservations Team</b><br>'
                email_content += `<div style="display:flex;"><img src=${this.itinerary.company.company_logo} width="30%" style="padding-right:30px;object-fit:contain;" />`
                email_content += '<div>' + this.itinerary.company.email + '<br>' + this.itinerary.company.website
                if (this.itinerary.company_id == 3) {
                    email_content += '<br>Office Number: +353-1-9053955'
                }
                if (this.itinerary.company_id == 4) {
                    email_content += '<br>Office Number: 1866 376 5645 | 01 213 516 9674<br>Dubai Silicon Oasis DDP Bldg. A1 Dubai UAE'
                }
                if (this.itinerary.company_id == 5 || this.itinerary.company_id == 6) {
                    email_content += '<br>Office Number: 1800 686 5645'
                }
                email_content += '</div></div><br>'
                if (this.itinerary.company_id == 4) {
                    email_content += '<i>A sister company of <label style="color:green;">GoIrishTours</label></i>'
                }
                email_content += '</p>'
            }
            else if (type == 'flight-details-escorted') {
                subject = 'Escorted Tour: Request for Flight Details'
                email_content += '<p>Hi ' + client_first_name + client_last_name + ',<br><br>'
                email_content += 'Not long now until you arrive in ' + country + '!<br><br>'
                email_content += 'You must be so excited about visiting our wonderful country<br><br>'
                email_content += 'We would like to request your flight details at your earliest convenience. This information is crucial for ensuring an efficient pick-up arrangement by your designated driver.<br><br>'
                email_content += 'Please include the following details:<br><br></p>'
                email_content += '<ul><li>Airline Name and Flight Number</li>'
                email_content += '<li>Departure and Arrival Times</li>'
                email_content += '<li>Departure and Arrival Airports</li>'
                email_content += "<li>*(Optional) Phone Number to reach you while you're in the country:</li></ul><br><br>"
                email_content += '<p>Looking forward to your arrival!<br><br><br>'
                email_content += 'Regards,<br>'
                email_content += '<b>Reservations Team</b><br>'
                email_content += `<div style="display:flex;"><img src=${this.itinerary.company.company_logo} width="30%" style="padding-right:30px;object-fit:contain;" />`
                email_content += '<div>' + this.itinerary.company.email + '<br>' + this.itinerary.company.website
                if (this.itinerary.company_id == 3) {
                    email_content += '<br>Office Number: +353-1-9053955'
                }
                if (this.itinerary.company_id == 4) {
                    email_content += '<br>Office Number: 1866 376 5645 | 01 213 516 9674<br>Dubai Silicon Oasis DDP Bldg. A1 Dubai UAE'
                }
                if (this.itinerary.company_id == 5 || this.itinerary.company_id == 6) {
                    email_content += '<br>Office Number: 1800 686 5645'
                }
                email_content += '</div></div><br>'
                if (this.itinerary.company_id == 4) {
                    email_content += '<i>A sister company of <label style="color:green;">GoIrishTours</label></i>'
                }
                email_content += '</p>'
            }
            else if (type == 'flight-details-self-drive') {
                subject = 'Self Drive: Request for Flight Details'
                email_content += '<p>Hi ' + client_first_name + client_last_name + ',<br><br>'
                email_content += "It's not long now until you arrive in " + country + "! You must be so excited about coming to our wonderful country.<br><br>"
                email_content += 'To ensure your itinerary in ' + country + ' begins and ends smoothly, we would like to request your flight details.<br><br>'
                email_content += 'Could you please provide the following information:<br><br></p>'
                email_content += '<ul><li>Airline Name and Flight Number</li>'
                email_content += '<li>Departure and Arrival Times</li>'
                email_content += '<li>Departure and Arrival Airports</li>'
                email_content += "<li>*(Optional) Phone Number to reach you while you're in the country:</li></ul><br><br>"
                email_content += '<p>You may reply with your flight details to this email or send them to your travel agent.<br><br>'
                email_content += 'Thank you, and have a wonderful day!<br><br><br>'
                email_content += 'Regards,<br>'
                email_content += '<b>Reservations Team</b><br>'
                email_content += `<div style="display:flex;"><img src=${this.itinerary.company.company_logo} width="30%" style="padding-right:30px;object-fit:contain;" />`
                email_content += '<div>' + this.itinerary.company.email + '<br>' + this.itinerary.company.website
                if (this.itinerary.company_id == 3) {
                    email_content += '<br>Office Number: +353-1-9053955'
                }
                if (this.itinerary.company_id == 4) {
                    email_content += '<br>Office Number: 1866 376 5645 | 01 213 516 9674<br>Dubai Silicon Oasis DDP Bldg. A1 Dubai UAE'
                }
                if (this.itinerary.company_id == 5 || this.itinerary.company_id == 6) {
                    email_content += '<br>Office Number: 1800 686 5645'
                }
                email_content += '</div></div><br>'
                if (this.itinerary.company_id == 4) {
                    email_content += '<i>A sister company of <label style="color:green;">GoIrishTours</label></i>'
                }
                email_content += '</p>'
            }

            let cc_emails = ''
            if (this.itinerary.contributors.length > 0 && this.itinerary.contributors.filter(x => x.user.user_role_id == 2).length > 0) {
                cc_emails = this.itinerary.contributors.filter(x => x.user.user_role_id == 2)[0].user.email + ','
            }

            this.email_to_send = {
                itinerary_id: this.itinerary.id,
                traveller_id: this.selected_travellers[0].id,
                traveller_emails: all_traveller_emails,
                cc_emails: cc_emails,
                reservations_email: this.itinerary.company.email,
                subject: subject,
                content: email_content,
                type: type,
            }
        },
        closeTravellerEmailDialog() {
            this.traveller_email_dialog = false
        },
        async sendTravellerEmails(data) {
            if (data.content == null || data.content == '' || data.subject == null || data.subject == '' || data.traveller_emails == null || data.traveller_emails == '') {
                this.$toast.error('Please fill out all fields')
                return
            }
            await this.$axios.post('v2/itineraries/emails/send_traveller_emails', data)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeTravellerEmailDialog()
                        this.selected_travellers = []
                    }
                })
        },
    },
}
</script>

<style scoped>
.access-label {
    color: #669E53;
    background-color: #E6F3E2;
    font-size: 12px;
    border: solid 1px #669E53;
    border-radius: 4px;
    padding: 3px 5px;
    margin-right: 5px;
}
</style>